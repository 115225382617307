import React from 'react';

export default function renderIcon({
  color = '#fff',
  width = 37.183,
  height = 37.183,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 37.183 37.183"
    >
      <g id="Group_579" data-name="Group 579" transform="translate(0 0)">
        <path
          id="Path_3654"
          data-name="Path 3654"
          d="M1204.217,3101.8a.563.563,0,0,1-.563-.563v-1.583a.563.563,0,1,1,1.127,0v1.583A.564.564,0,0,1,1204.217,3101.8Z"
          transform="translate(-1185.626 -3096.363)"
          fill={color}
        />
        <path
          id="Path_3655"
          data-name="Path 3655"
          d="M1204.217,3227.589a.563.563,0,0,1-.563-.563v-1.583a.563.563,0,1,1,1.127,0v1.583A.564.564,0,0,1,1204.217,3227.589Z"
          transform="translate(-1185.626 -3193.127)"
          fill={color}
        />
        <path
          id="Path_3656"
          data-name="Path 3656"
          d="M1139.474,3166.54h-1.583a.563.563,0,0,1,0-1.127h1.583a.563.563,0,0,1,0,1.127Z"
          transform="translate(-1134.605 -3147.385)"
          fill={color}
        />
        <path
          id="Path_3657"
          data-name="Path 3657"
          d="M1265.267,3166.54h-1.584a.563.563,0,0,1,0-1.127h1.584a.563.563,0,0,1,0,1.127Z"
          transform="translate(-1231.369 -3147.385)"
          fill={color}
        />
        <path
          id="Path_3658"
          data-name="Path 3658"
          d="M1171.846,3110.47a.564.564,0,0,1-.489-.282l-.792-1.371a.564.564,0,0,1,.976-.563l.792,1.371a.563.563,0,0,1-.487.845Z"
          transform="translate(-1160.115 -3103.199)"
          fill={color}
        />
        <path
          id="Path_3659"
          data-name="Path 3659"
          d="M1234.742,3219.41a.563.563,0,0,1-.488-.282l-.792-1.371a.563.563,0,0,1,.976-.564l.792,1.371a.564.564,0,0,1-.488.846Z"
          transform="translate(-1208.497 -3186.999)"
          fill={color}
        />
        <path
          id="Path_3660"
          data-name="Path 3660"
          d="M1146.776,3197.065a.564.564,0,0,1-.282-1.052l1.371-.792a.563.563,0,0,1,.563.976l-1.371.792A.561.561,0,0,1,1146.776,3197.065Z"
          transform="translate(-1141.44 -3170.256)"
          fill={color}
        />
        <path
          id="Path_3661"
          data-name="Path 3661"
          d="M1171.053,3219.409a.564.564,0,0,1-.488-.845l.792-1.371a.564.564,0,0,1,.976.564l-.792,1.371A.563.563,0,0,1,1171.053,3219.409Z"
          transform="translate(-1160.115 -3186.998)"
          fill={color}
        />
        <path
          id="Path_3662"
          data-name="Path 3662"
          d="M1148.147,3134.167a.559.559,0,0,1-.281-.076l-1.371-.792a.563.563,0,1,1,.564-.976l1.371.792a.564.564,0,0,1-.282,1.052Z"
          transform="translate(-1141.44 -3121.874)"
          fill={color}
        />
        <path
          id="Path_3663"
          data-name="Path 3663"
          d="M1257.087,3197.064a.563.563,0,0,1-.281-.076l-1.371-.792a.563.563,0,1,1,.563-.976l1.371.792a.564.564,0,0,1-.282,1.052Z"
          transform="translate(-1225.24 -3170.256)"
          fill={color}
        />
        <path
          id="Path_3664"
          data-name="Path 3664"
          d="M1144.125,3124.475a18.592,18.592,0,1,1,18.592-18.591A18.612,18.612,0,0,1,1144.125,3124.475Zm0-36.056a17.465,17.465,0,1,0,17.465,17.465A17.485,17.485,0,0,0,1144.125,3088.419Z"
          transform="translate(-1125.533 -3087.292)"
          fill={color}
        />
        <path
          id="Path_3665"
          data-name="Path 3665"
          d="M1193.855,3136.708h-6.862a.564.564,0,0,1-.488-.281l-5.167-8.95a.563.563,0,0,1,.976-.563l5,8.668h6.536a.563.563,0,1,1,0,1.126Z"
          transform="translate(-1168.402 -3117.552)"
          fill={color}
        />
        <path
          id="Path_3666"
          data-name="Path 3666"
          d="M1229.283,3116.639a.563.563,0,0,1-.553-.458c-.014-.071-.027-.142-.042-.213a.563.563,0,1,1,1.1-.227q.023.114.046.229a.562.562,0,0,1-.554.668Zm-.985-3.154a.563.563,0,0,1-.515-.335c-.03-.066-.059-.132-.09-.2a.563.563,0,1,1,1.022-.474q.049.106.1.214a.564.564,0,0,1-.515.792Zm-1.678-2.849a.563.563,0,0,1-.45-.224q-.065-.087-.132-.172a.563.563,0,1,1,.889-.692q.072.092.142.186a.563.563,0,0,1-.449.9Zm-2.278-2.4a.561.561,0,0,1-.362-.132l-.166-.137a.563.563,0,1,1,.709-.875l.181.149a.563.563,0,0,1-.362,1Zm-2.758-1.821a.563.563,0,0,1-.255-.061l-.194-.1a.564.564,0,0,1,.5-1.012l.208.1a.564.564,0,0,1-.255,1.066Zm-3.1-1.148a.565.565,0,0,1-.134-.016l-.21-.049a.564.564,0,0,1,.25-1.1l.228.054a.563.563,0,0,1-.133,1.111Z"
          transform="translate(-1196.434 -3100.215)"
          fill={color}
        />
      </g>
    </svg>
  );
}
