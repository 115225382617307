import styled from 'styled-components';

export const ActivityStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 48%;
  background-color: ${({ theme }) => theme.COLORS.BLUE_1};
  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-size: cover;
  cursor: pointer;
  .activity_registed_wrap {
    position: absolute;
    right: 14px;
    bottom: 18px;
    z-index: 2;
    img {
      width: 150px;
      height: auto;
    }
  }

  .theme_standard {
  }
`;
