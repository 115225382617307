import { useState } from 'react';
import { ExchangeRewardInputStep1Styled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RenderCommaMoney } from 'utils/functions/formatMoney';
import { Buttons, Inputs } from 'components';
import moment from 'moment';

const ExchangeRewardInputStep1 = ({ data, onCancel, onAccept }) => {
  const [_amount, _setAmount] = useState(1);

  const _handleChangeNumber = (e) => {
    _setAmount(e);
  };

  return (
    <ExchangeRewardInputStep1Styled>
      <div className="body_exchange">
        <div
          className="img_show"
          style={{
            backgroundImage: `url(${IMG_PATH + data.reward_img_path})`,
          }}
        >
          {data && (
            <div className="detail_block">
              <div className="db_amout_label">
                {`จำนวนที่เหลือ : ${RenderCommaMoney(
                  data.total_amount - data.redeem_amount
                )}`}
              </div>
              <div className="db_expire_label">
                {' '}
                {`แลกได้ถึงวันที่ ${moment(data.expired_at).format(
                  'DD/MM/YY'
                )}`}
              </div>
            </div>
          )}
        </div>
        <div className="label_show">
          <div className="box_label">
            แลกเพียง {RenderCommaMoney(data.redeem_point)} คะแนน
          </div>
        </div>
        <div className="title">{data.name}</div>
        <div className="sub_text">{data.description}</div>
        <div className="amount_label">จำนวนของรางวัลที่ต้องการแลก</div>
        <div className="input_wrap">
          <Inputs.InputAmout
            max={data.total_amount}
            onChange={_handleChangeNumber}
          />
        </div>
        <div className="btn_layout">
          <div className="btn_body">
            <Buttons.BgStandard
              theme_cancel_btn
              label={'ย้อนกลับ'}
              onClick={onCancel}
            />
          </div>
          {data.total_amount - data.redeem_amount > 0 && (
            <div className="btn_body">
              <Buttons.BgStandard
                theme_stadard_btn
                label={'ยืนยัน'}
                onClick={() => onAccept(_amount)}
              />
            </div>
          )}
        </div>
      </div>
    </ExchangeRewardInputStep1Styled>
  );
};

export default ExchangeRewardInputStep1;
