import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { HeaderRowPanelStyled } from './styled';

const HeaderRowPanel = ({
  theme_standard,
  tableHeader,
  isLoading,
  columns,
  data,
  onClickRow,
  useNo,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const getDescendantProp = (obj, desc) => {
    var arr = desc.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  return (
    <HeaderRowPanelStyled>
      <div className={customClass}>
        <div className="table_container">
          {tableHeader && <div className="table_title">{tableHeader}</div>}
          <div className="table_header">
            <div className="header_row">
              {columns &&
                columns.map((e, i) => (
                  <div key={i} className="header_col" style={e.headerStyle}>
                    {e.renderTitle ? e.renderTitle : e.title}
                  </div>
                ))}
            </div>
          </div>
          <div className="table_body">
            {isLoading ? (
              'loading'
            ) : !data || data.length === 0 ? (
              <div className="label_empty">No data</div>
            ) : (
              data.map((e, i) => {
                return (
                  <div key={i} className="body_row_wrap">
                    <div
                      className="body_row"
                      style={e.rowSty ? e.rowSty : {}}
                      onClick={() => {
                        onClickRow && onClickRow(e);
                      }}
                    >
                      {columns.map((e1, i2) => (
                        <div key={i2} className="body_col" style={e1.style}>
                          {useNo && i2 === 0 ? (
                            <div className="no">
                              {e1.render
                                ? e1.render({
                                    origin: e,
                                    value: i + 1,
                                  })
                                : i + 1}
                            </div>
                          ) : e1.render ? (
                            e1.render({
                              origin: e,
                              value: getDescendantProp(e, e1.field),
                            })
                          ) : (
                            getDescendantProp(e, e1.field)
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </HeaderRowPanelStyled>
  );
};

HeaderRowPanel.propTypes = {};

export default HeaderRowPanel;
