import React, { useEffect } from 'react';
import { ActivityAcceptType2FormStyled } from './styled';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ActivityAcceptType2Form = ({ data, onSubmit }) => {
  const schema = yup.object().shape({
    is_agreement: yup
      .bool()
      .nullable()
      .oneOf([true], 'Please accept condition'),
  });

  const {
    control,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      campaign_serial_no: [
        ...Array.from(Array(data.text_inpul_number).keys()).map((e) => ({
          id: e,
          code: '',
        })),
      ],
    },
  });

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'campaign_serial_no', // unique name for your Field Array
  });

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = (values) => {
    let error = false;
    values.campaign_serial_no.forEach((e, i) => {
      if (!e.code) {
        setError(`campaign_serial_no[${i}].code`, {
          type: 'manual',
          message: 'Please select the required options',
        });
        error = true;
      }
    });
    if (!error) {
      onSubmit && onSubmit(values);
    }
  };

  return (
    <ActivityAcceptType2FormStyled>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        {fields.map((field, index) => (
          <div key={field.id} className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  theme_standard
                  placeholder={
                    data.text_input_label || 'กรุณากรอกเลขที่ PO 12 หลัก'
                  }
                  errors={
                    errors.campaign_serial_no &&
                    errors?.campaign_serial_no[index]?.code?.message
                  }
                />
              )}
              name={`campaign_serial_no.${index}.code`}
              defaultValue=""
            />
          </div>
        ))}
        {/* <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                theme_standard
                placeholder={'กรุณากรอกเลขที่ PO 12 หลัก'}
                errors={errors.po?.message}
              />
            )}
            name="po"
            defaultValue=""
          />
        </div> */}
        <div className="input_checkbox_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label="ยอมรับเงื่อนไขกิจกรรม"
                disabled={data?.isRegisted}
                errors={errors.is_agreement?.message}
              />
            )}
            name="is_agreement"
            defaultValue={data?.isRegisted || false}
          />
        </div>
        {data?.isRegisted ? (
          <Buttons.BgStandard
            type="button"
            disabled
            txtColor={data.btn_joined_text_color}
            bgColor={data.btn_joined_button_color}
            label={data.btn_joined_label || 'คุณเข้าร่วมกิจกรรมแล้ว'}
          />
        ) : (
          <Buttons.BgStandard
            type="submit"
            bgColor={data?.btn_join_button_color}
            txtColor={data?.btn_join_text_color}
            label={data?.btn_join_label || 'ยืนยันเข้าร่วมกิจกรรม'}
          />
        )}
      </form>
    </ActivityAcceptType2FormStyled>
  );
};

ActivityAcceptType2Form.propTypes = {};

export default ActivityAcceptType2Form;
