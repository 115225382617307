import React, { useEffect } from 'react';
import { ProfileFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ProfileForm = ({ initialValues, onSubmit }) => {
  const schema = yup.object().shape({
    firstname: yup.string().required('Firstname is required'),
    lastname: yup.string().required('Lastname is required'),
    address: yup.string().required('Address is required'),
    cu_id: yup.string().required('Cu id is required'),
    phone_number: yup.string().required('Phone number is required'),
    email: yup.string().required('Email is required'),
    user_id: yup.string().required('User id is required'),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProfileFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_title">โปรไฟล์ของคุณ</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'ชื่อ'}
                errors={errors.firstname?.message}
              />
            )}
            name="firstname"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'นามสกุล'}
                errors={errors.lastname?.message}
              />
            )}
            name="lastname"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextArea
                {...field}
                label={'ที่อยู่จัดส่งของรางวัล'}
                errors={errors.address?.message}
              />
            )}
            name="address"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'รหัสลูกค้า 6 หลัก'}
                disabled
                errors={errors.cu_id?.message}
              />
            )}
            name="cu_id"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'เบอร์โทรศัพท์มือถือ'}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'Email'}
                errors={errors.email?.message}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_40">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'User ID'}
                disabled
                errors={errors.user_id?.message}
              />
            )}
            name="user_id"
            defaultValue=""
          />
        </div>
        <div className="btn_submit_wrap">
          <Buttons.BgStandard
            theme_stadard_btn
            type="submit"
            label={'บันทึก'}
          />
        </div>
      </form>
    </ProfileFormStyled>
  );
};

ProfileForm.propTypes = {};

export default ProfileForm;
