import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LogoStyled } from './styled';

const Logo = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LogoStyled>
      <div className={customClass}>
        <img
          className="header_img"
          alt="misumi header"
          src="/assets/images/logos/logo-bg.png"
        />
      </div>
    </LogoStyled>
  );
};

Logo.propTypes = {};

export default Logo;
