import React from 'react';

export default function renderIcon({
  color = '#fff',
  width = '35.944',
  height = '35.944',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.944 35.944"
    >
      <g id="Group_580" data-name="Group 580" transform="translate(0 0)">
        <path
          id="Path_3667"
          data-name="Path 3667"
          d="M627.442,3244.358a.542.542,0,0,1-.542-.542v-1.291a.542.542,0,0,1,1.085,0v1.291A.542.542,0,0,1,627.442,3244.358Z"
          transform="translate(-609.471 -3208.414)"
          fill={color}
        />
        <path
          id="Path_3668"
          data-name="Path 3668"
          d="M627.442,3089.609a.542.542,0,0,1-.542-.542v-1.291a.542.542,0,0,1,1.085,0v1.291A.542.542,0,0,1,627.442,3089.609Z"
          transform="translate(-609.471 -3087.233)"
          fill={color}
        />
        <path
          id="Path_3669"
          data-name="Path 3669"
          d="M703.131,3168.668H701.84a.542.542,0,1,1,0-1.084h1.291a.542.542,0,1,1,0,1.084Z"
          transform="translate(-667.73 -3150.154)"
          fill={color}
        />
        <path
          id="Path_3670"
          data-name="Path 3670"
          d="M548.383,3168.668h-1.291a.542.542,0,1,1,0-1.084h1.291a.542.542,0,1,1,0,1.084Z"
          transform="translate(-546.55 -3150.154)"
          fill={color}
        />
        <path
          id="Path_3671"
          data-name="Path 3671"
          d="M680.963,3222.189a.541.541,0,0,1-.384-.159l-.913-.913a.542.542,0,0,1,.767-.767l.913.913a.542.542,0,0,1-.383.926Z"
          transform="translate(-650.667 -3191.35)"
          fill={color}
        />
        <path
          id="Path_3672"
          data-name="Path 3672"
          d="M571.539,3112.765a.54.54,0,0,1-.383-.159l-.913-.913a.542.542,0,0,1,.767-.767l.913.913a.543.543,0,0,1-.384.926Z"
          transform="translate(-564.979 -3105.662)"
          fill={color}
        />
        <path
          id="Path_3673"
          data-name="Path 3673"
          d="M680.05,3112.765a.543.543,0,0,1-.384-.926l.913-.913a.542.542,0,0,1,.767.767l-.913.913A.54.54,0,0,1,680.05,3112.765Z"
          transform="translate(-650.667 -3105.662)"
          fill={color}
        />
        <path
          id="Path_3674"
          data-name="Path 3674"
          d="M570.626,3222.189a.542.542,0,0,1-.384-.926l.913-.913a.542.542,0,0,1,.767.767l-.913.913A.541.541,0,0,1,570.626,3222.189Z"
          transform="translate(-564.979 -3191.35)"
          fill={color}
        />
        <path
          id="Path_3675"
          data-name="Path 3675"
          d="M656.407,3238.6a.542.542,0,0,1-.5-.335l-.494-1.193a.542.542,0,0,1,1-.415l.494,1.193a.543.543,0,0,1-.5.75Z"
          transform="translate(-631.765 -3203.978)"
          fill={color}
        />
        <path
          id="Path_3676"
          data-name="Path 3676"
          d="M597.187,3095.626a.542.542,0,0,1-.5-.335l-.494-1.193a.542.542,0,0,1,1-.415l.494,1.193a.542.542,0,0,1-.294.708A.537.537,0,0,1,597.187,3095.626Z"
          transform="translate(-585.391 -3092.022)"
          fill={color}
        />
        <path
          id="Path_3677"
          data-name="Path 3677"
          d="M696.177,3138.413a.542.542,0,0,1-.208-1.043l1.193-.494a.542.542,0,0,1,.415,1l-1.193.494A.537.537,0,0,1,696.177,3138.413Z"
          transform="translate(-663.295 -3126.074)"
          fill={color}
        />
        <path
          id="Path_3678"
          data-name="Path 3678"
          d="M553.208,3197.633a.542.542,0,0,1-.208-1.043l1.193-.494a.542.542,0,1,1,.415,1l-1.193.494A.537.537,0,0,1,553.208,3197.633Z"
          transform="translate(-551.339 -3172.449)"
          fill={color}
        />
        <path
          id="Path_3679"
          data-name="Path 3679"
          d="M697.369,3197.633a.537.537,0,0,1-.207-.042l-1.193-.494a.542.542,0,1,1,.415-1l1.193.494a.542.542,0,0,1-.208,1.043Z"
          transform="translate(-663.295 -3172.449)"
          fill={color}
        />
        <path
          id="Path_3680"
          data-name="Path 3680"
          d="M554.4,3138.413a.541.541,0,0,1-.207-.041l-1.193-.494a.542.542,0,0,1,.415-1l1.193.494a.542.542,0,0,1-.208,1.043Z"
          transform="translate(-551.339 -3126.075)"
          fill={color}
        />
        <path
          id="Path_3681"
          data-name="Path 3681"
          d="M655.913,3095.627a.533.533,0,0,1-.207-.042.542.542,0,0,1-.293-.708l.494-1.193a.542.542,0,0,1,1,.415l-.494,1.193A.542.542,0,0,1,655.913,3095.627Z"
          transform="translate(-631.765 -3092.022)"
          fill={color}
        />
        <path
          id="Path_3682"
          data-name="Path 3682"
          d="M596.693,3238.6a.542.542,0,0,1-.5-.75l.494-1.193a.542.542,0,0,1,1,.415l-.494,1.193A.543.543,0,0,1,596.693,3238.6Z"
          transform="translate(-585.391 -3203.979)"
          fill={color}
        />
        <path
          id="Path_3683"
          data-name="Path 3683"
          d="M580.932,3137.869a1.784,1.784,0,0,1-1.753-2.083l.942-5.493a.7.7,0,0,0-.2-.616l-3.991-3.89a1.781,1.781,0,0,1,.987-3.037l5.516-.8a.7.7,0,0,0,.524-.381l2.467-5a1.77,1.77,0,0,1,1.6-.993h0a1.769,1.769,0,0,1,1.6.993l2.467,5a.7.7,0,0,0,.524.381l5.515.8a1.781,1.781,0,0,1,.987,3.037l-3.991,3.89a.7.7,0,0,0-.2.616l.942,5.493a1.781,1.781,0,0,1-2.584,1.877l-4.933-2.593a.694.694,0,0,0-.648,0l-4.933,2.593A1.781,1.781,0,0,1,580.932,3137.869Zm6.087-3.965a1.781,1.781,0,0,1,.829.2l4.933,2.594a.7.7,0,0,0,1.01-.734l-.942-5.493a1.78,1.78,0,0,1,.512-1.576l3.991-3.89a.7.7,0,0,0-.386-1.187l-5.516-.8a1.78,1.78,0,0,1-1.341-.974l-2.467-5h0a.7.7,0,0,0-1.248,0l-2.467,5a1.779,1.779,0,0,1-1.341.974l-5.516.8a.7.7,0,0,0-.386,1.188l3.991,3.89a1.779,1.779,0,0,1,.512,1.576l-.942,5.493a.7.7,0,0,0,1.01.734l4.933-2.594A1.78,1.78,0,0,1,587.02,3133.9Z"
          transform="translate(-569.135 -3109.428)"
          fill={color}
        />
      </g>
    </svg>
  );
}
