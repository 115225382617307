import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BottomTabStyled } from './styled';
// import { useNavigate } from 'react-router-dom';

const BottomTab = ({ theme_standard, data }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  // const navigate = useNavigate();

  const [_active, _setActive] = useState();
  const [_selected, _setSelected] = useState(0);

  useEffect(() => {
    if (data) {
      _setActive(data[0].key);
      _handleClickMenu(data[0]);
      _setSelected(0)
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClickMenu = (e, index) => {
    _setSelected(index)
    _setActive(e.key);
  };

  return (
    <BottomTabStyled>
      <div className={customClass}>
        <div className="bottom_tap_header_layout">
          {data &&
            data.map((e, i) => (
              <div
                key={i}
                className={`header_item ${_active === e.key ? 'acitve' : ''}`}
                onClick={() => _handleClickMenu(e, i)}
              >
                {e.name}
              </div>
            ))}
        </div>
        <div className="body_tap_body_layout">
          {data && data[_selected].element}
        </div>
      </div>
    </BottomTabStyled>
  );
};

BottomTab.propTypes = {};

export default BottomTab;
