import styled from 'styled-components';

export const TransactionHistoryContainerStyled = styled.div`
  padding: 0px 0px 125px 0px;
  .expire_point_label {
    margin-top: 6px;
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
  }
`;
