import styled from 'styled-components';

export const ActivityDetailStyled = styled.div`
  .ad_campaign_img {
    width: 100%;
    height: auto;
  }

  .theme_standard {
  }
`;
