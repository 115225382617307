import Activity from './Activity';
import ActivityDetail from './ActivityDetail';
import MusumiStatus from './MusumiStatus';
import ProcessStatus from './ProcessStatus';
import Reward from './Reward';

const EXPORT = {
  Activity,
  ActivityDetail,
  MusumiStatus,
  ProcessStatus,
  Reward,
};

export default EXPORT;
