import { ExchangeRewardStep1Styled } from './styled';
import moment from 'moment';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RenderCommaMoney } from 'utils/functions/formatMoney';
import { Buttons } from 'components';

const ExchangeRewardStep1 = ({ data, onCancel, onAccept }) => {
  return (
    <ExchangeRewardStep1Styled>
      <div className="body_exchange">
        <div
          className="img_show"
          style={{
            backgroundImage: `url(${IMG_PATH + data.reward_img_path})`,
          }}
        >
          {data && (
            <div className="detail_block">
              <div className="db_amout_label">
                {`จำนวนที่เหลือ : ${RenderCommaMoney(
                  data.total_amount - data.redeem_amount
                )}`}
              </div>
              <div className="db_expire_label">
                {' '}
                {`แลกได้ถึงวันที่ ${moment(data.expired_at).format(
                  'DD/MM/YY'
                )}`}
              </div>
            </div>
          )}
        </div>
        <div className="label_show">
          <div className="box_label">
            แลกเพียง {RenderCommaMoney(data.redeem_point)} คะแนน
          </div>
        </div>
        <div className="title">{data.name}</div>
        <div className="sub_text">{data.description}</div>
        <div className="small_text">แลกได้ถึงวันที่</div>
        <div className="small_text mb">
          {moment(data.expired_at).format('DD MMMM YYYY')}
        </div>
        <div className="btn_layout">
          <div className="btn_body">
            <Buttons.BgStandard
              theme_cancel_btn
              label={'ย้อนกลับ'}
              onClick={onCancel}
            />
          </div>
          {data.total_amount - data.redeem_amount > 0 && (
            <div className="btn_body">
              <Buttons.BgStandard
                theme_stadard_btn
                label={'ยืนยัน'}
                onClick={onAccept}
              />
            </div>
          )}
        </div>
      </div>
    </ExchangeRewardStep1Styled>
  );
};

export default ExchangeRewardStep1;
