import styled from 'styled-components';

export const MusumiStatusStyled = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 354px;
  padding: 30px 16px 19px 16px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  text-align: center;
  .ms_logo_wrap {
    width: 139px;
    margin: auto;
    .ms_logo {
      width: 100%;
      height: auto;
    }
  }
  .ps_title {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
  }
  .ps_label {
    margin-bottom: 28px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
  }
  .ps_btn_wrap {
    margin: auto;
    max-width: 153px;
  }

  .theme_standard {
  }
`;
