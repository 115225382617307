import { useEffect, useState } from 'react';
import { MenuBarWidgetStyled } from './styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import theme from 'styles/theme.json';
import { useSelector } from 'react-redux';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Icons } from 'components';

const MenuBarWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { configSystemRedux } = useSelector((state) => state);

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split('/');
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.routePath);
  };
  return (
    <MenuBarWidgetStyled bg={configSystemRedux?.footer_bg_img}>
      {menuList &&
        menuList.map((e, i) => (
          <div
            key={i}
            className={`menu_wrap ${_active === e.parentMenu ? 'active' : ''}`}
            onClick={() => _handleClickMenu(e)}
          >
            <div className="menu_item">
              <div className="menu_img_figture">
                {_active === e.parentMenu ? (
                  configSystemRedux[`menu${i + 1}_active_img`] ? (
                    <img
                      className="menu_img"
                      alt="menu"
                      src={
                        IMG_PATH + configSystemRedux[`menu${i + 1}_active_img`]
                      }
                    />
                  ) : (
                    e.iconAct
                  )
                ) : configSystemRedux[`menu${i + 1}_img`] ? (
                  <img
                    className="menu_img"
                    alt="menu"
                    src={IMG_PATH + configSystemRedux[`menu${i + 1}_img`]}
                  />
                ) : (
                  e.icon
                )}
              </div>
              <div className="mi_label">{e.label}</div>
            </div>
          </div>
        ))}
    </MenuBarWidgetStyled>
  );
};

const menuList = [
  {
    parentMenu: 'activity',
    label: 'กิจกรรม',
    icon: <Icons.MenuActivity />,
    iconAct: <Icons.MenuActivity color={theme.COLORS.ORANGE_1} />,
    routePath: ROUTE_PATH.ACTIVITY,
  },
  {
    parentMenu: 'reward',
    label: 'ของรางวัล',
    icon: <Icons.MenuReward />,
    iconAct: <Icons.MenuReward color={theme.COLORS.ORANGE_1} />,
    routePath: ROUTE_PATH.REWARD,
  },
  {
    parentMenu: 'history',
    label: 'ประวัติ',
    icon: <Icons.MenuHistory />,
    iconAct: <Icons.MenuHistory color={theme.COLORS.ORANGE_1} />,
    routePath: ROUTE_PATH.HISTORY,
  },
  {
    parentMenu: 'profile',
    label: 'โปรไฟล์',
    icon: <Icons.MenuProfile />,
    iconAct: <Icons.MenuProfile color={theme.COLORS.ORANGE_1} />,
    routePath: ROUTE_PATH.PROFILE,
  },
];

export default MenuBarWidget;
