import styled from 'styled-components';

export const TextAreaStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  textarea {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
    width: 100%;
    height: 122px;
    box-sizing: border-box;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.GRAY_4 : theme.COLORS.GRAY_3};
    padding: 8px 9px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    cursor: ${({ disabled }) => (!disabled ? 'auto' : 'not-allowed')};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
