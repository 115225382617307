import React from "react";
import { TransactionHistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { userService } from "apiServices";
import { RenderCommaMoney } from "utils/functions/formatMoney";
import { Displays, Tables } from "components";

class TransactionHistoryContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_MY_POINT_HISTORY();
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
        // pointExpire: this.calcPointExpire(res.data),
      });
    }
  };

  // calcPointExpire = (e) => {
  //   // find Current
  //   let isBeforeRang = moment().isBefore(`${moment().format('YYYY')}-07-01`);
  //   if (isBeforeRang) {
  //     return e.reduce(
  //       (result, data) => {
  //         // Latest rang
  //         const currentMoment = moment(
  //           moment(data.created_at).format('DD-MM-YYYY'),
  //           'DD-MM-YYYY'
  //         );
  //         const startDateMoment = moment(
  //           `01-07-${moment().subtract(1, 'years').format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         const endDateMoment = moment(
  //           `31-12-${moment().subtract(1, 'years').format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         if (
  //           currentMoment.isBetween(startDateMoment, endDateMoment) ||
  //           currentMoment.isSame(startDateMoment) ||
  //           currentMoment.isSame(endDateMoment)
  //         ) {
  //           result.dataBefor.point += data.point;
  //           result.dataBefor.data.push(data);
  //         }

  //         // current rang
  //         const currentCMoment = moment(data.created_at);
  //         const startCDateMoment = moment(
  //           `01-01-${moment().format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         const endCDateMoment = moment(
  //           `30-06-${moment().format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         if (
  //           currentCMoment.isBetween(startCDateMoment, endCDateMoment) ||
  //           currentCMoment.isSame(startCDateMoment) ||
  //           currentCMoment.isSame(endCDateMoment)
  //         ) {
  //           result.dataCurrentRang.point += data.point;
  //           result.dataCurrentRang.data.push(data);
  //         }
  //         return result;
  //       },
  //       {
  //         dataWarningExpire: { point: 0, data: [] },
  //         dataCurrentRang: { point: 0, data: [] },
  //       }
  //     );
  //   } else {
  //     return e.reduce(
  //       (result, data) => {
  //         const currentMoment = moment(
  //           moment(data.created_at).format('DD-MM-YYYY'),
  //           'DD-MM-YYYY'
  //         );
  //         const startDateMoment = moment(
  //           `01-01-${moment().format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         const endDateMoment = moment(`30-06-2022`, 'DD-MM-YYYY');
  //         if (
  //           currentMoment.isBetween(startDateMoment, endDateMoment) ||
  //           currentMoment.isSame(startDateMoment) ||
  //           currentMoment.isSame(endDateMoment)
  //         ) {
  //           result.dataWarningExpire.point += data.point;
  //           result.dataWarningExpire.data.push(data);
  //         }

  //         // current rang
  //         const currentCMoment = moment(data.created_at);
  //         const startCDateMoment = moment(
  //           `01-07-${moment().format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         const endCDateMoment = moment(
  //           `31-12-${moment().format('YYYY')}`,
  //           'DD-MM-YYYY'
  //         );
  //         if (
  //           currentCMoment.isBetween(startCDateMoment, endCDateMoment) ||
  //           currentCMoment.isSame(startCDateMoment) ||
  //           currentCMoment.isSame(endCDateMoment)
  //         ) {
  //           result.dataCurrentRang.point += data.point;
  //           result.dataCurrentRang.data.push(data);
  //         }
  //         return result;
  //       },
  //       {
  //         dataWarningExpire: { point: 0, data: [] },
  //         dataCurrentRang: { point: 0, data: [] },
  //       }
  //     );
  //   }
  // };

  render() {
    const { data, isLoading } = this.state;
    return (
      <TransactionHistoryContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Tables.HeaderRowPanel
            columns={columns()}
            data={data}
            onClickRow={this.handleClickHistory}
          />
        )}
        <div className="expire_point_label">
          *คะแนนที่ท่านได้รับตั้งแต่วันที่ 1 – 31 ธันวาคม 66 จะมีอายุถึง 30
          มิ.ย.67 **คะแนนที่ได้รับตั้งแต่วันที่ 1 ม.ค. 67 – 30 มิ.ย. 67
          จะมีอายุถึง 31 ธันวาคม 67 กรุณาใช้คะแนนดังกล่าวก่อนหมดอายุ
          {/* {moment().isBefore(`${moment().format('YYYY')}-07-01`)
            ? `คะแนนที่คุณได้รับตั้งแต่วันนี้ - 30 มิถุนายน ${moment()
                .add(543, 'years')
                .format('YYYY')} จะหมดอายุในวันที่ 31 ธันวาคม ${moment()
                .add(543, 'years')
                .format('YYYY')} กรุณาใช้คะแนนดังกล่าวก่อนหมดอายุ`
            : `คะแนนที่คุณได้รับตั้งแต่วันนี้ - 31 ธันวาคม ${moment()
                .add(543, 'years')
                .format('YYYY')} จะหมดอายุในวันที่ 30 มิถุนายน ${moment()
                .add(544, 'years')
                .format('YYYY')} กรุณาใช้คะแนนดังกล่าวก่อนหมดอายุ`} */}
        </div>
      </TransactionHistoryContainerStyled>
    );
  }
}

const columns = () => [
  {
    title: "วันที่",
    field: "created_at",
    headerStyle: { textAlign: "center" },
    render: ({ origin, value }) => (
      <div style={{ textAlign: "center" }}>
        {moment(value).format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    title: "กิจกรรม",
    field: "campaign_code",
  },
  {
    title: "ยอดสั่งซื้อ",
    field: "so_amount",
    render: ({ origin, value }) => RenderCommaMoney(value),
  },
  {
    title: "คะแนนที่ได้รับ",
    field: "point",
    style: { textAlign: "center" },
    render: ({ origin, value }) =>
      value === 0
        ? value
        : origin.status === "5"
        ? RenderCommaMoney(-value)
        : RenderCommaMoney(value),
  },
];

export default withRouter(TransactionHistoryContainer);
