import React from 'react';
import { ProfileContainerStyled } from './styled';
import { connect } from 'react-redux';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { Displays, Forms } from 'components';

class ProfileContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await userService.PUT_MY_PROFILE_UPDATE(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      this.props.setReduxAuthen(res.data);
      toast.success('อัพเดตสำเร็จ');
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('อัพเดตไม่สำเร็จ');
    }
  };

  render() {
    const { authenRedux } = this.props;
    const { isBgLoading } = this.state;
    return (
      <ProfileContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Forms.ProfileForm
          initialValues={authenRedux}
          onSubmit={this.handleSubmit}
        />
      </ProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileContainer));
