import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TopTabStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const TopTab = ({ theme_standard, data, showIcon }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const navigate = useNavigate();

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (data) {
      _setActive(data[0].key);
      _handleClickMenu(data[0]);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClickMenu = (e) => {
    navigate(e.path);
    _setActive(e.key);
  };

  const _renderImg = (e) => {
    switch (e) {
      case 'reward_eletronic':
        return "/assets/images/icons/reward1.svg"
      case 'reward_card':
        return "/assets/images/icons/reward2.svg"
      case 'reward_etc':
        return "/assets/images/icons/reward3.svg"
      default:
        break;
    }
  }

  return (
    <TopTabStyled>
      <div className={customClass}>
        <div className="top_tap_body_layout">
          <Outlet />
        </div>
        <div className="top_tap_header_layout">
          {data &&
            data.map((e, i) => (
              <div
                key={i}
                className={`header_item ${_active === e.key ? 'acitve' : ''}`}
                onClick={() => _handleClickMenu(e)}
              >
                {
                  showIcon &&
                  <img className={`img_show ${_active === e.key ? `show ${e.key}` : ''}`} alt="img_tab" src={_renderImg(e.key)} />
                }
                {e.name}
              </div>
            ))}
        </div>
      </div>
    </TopTabStyled>
  );
};

TopTab.propTypes = {};

export default TopTab;
