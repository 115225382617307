import React from 'react';

export default function renderIcon({
  width = 21,
  height = 21,
  color = '#9ca2b8',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="91.918" viewBox="0 0 59 91.918">
      <g id="Group_655" data-name="Group 655" transform="translate(-4940.593 -2906.997)">
        <g id="Group_639" data-name="Group 639" transform="translate(4940.593 2906.997)">
          <rect id="Rectangle_921" data-name="Rectangle 921" width="48.352" height="86.45" rx="10.673" transform="translate(0 6.177) rotate(-7.34)" fill="#9f9bf3" />
          <path id="Path_3896" data-name="Path 3896" d="M4958.773,2998.851a9.144,9.144,0,0,1-9.048-7.975l-8.937-69.38a9.151,9.151,0,0,1,7.9-10.234l31.594-4.07a9.152,9.152,0,0,1,10.234,7.9l8.937,69.38a9.143,9.143,0,0,1-7.9,10.234l-31.594,4.069A9.252,9.252,0,0,1,4958.773,2998.851Zm22.682-89.948a7.377,7.377,0,0,0-.945.061l-31.594,4.069a7.365,7.365,0,0,0-6.355,8.235l8.937,69.38a7.355,7.355,0,0,0,8.234,6.355l31.595-4.069h0a7.356,7.356,0,0,0,6.355-8.235l-8.937-69.38A7.366,7.366,0,0,0,4981.455,2908.9Z" transform="translate(-4940.62 -2907.024)" fill="#47327c" />
        </g>
        <g id="Group_640" data-name="Group 640" transform="translate(4941.115 2917.09)">
          <rect id="Rectangle_922" data-name="Rectangle 922" width="48.352" height="67.124" transform="translate(0.893 7.07) rotate(-7.34)" fill="#fff" />
          <path id="Path_3897" data-name="Path 3897" d="M4950.735,2994.594a.893.893,0,0,1-.884-.779l-8.576-66.574a.894.894,0,0,1,.772-1l47.956-6.177a.892.892,0,0,1,1,.772l8.576,66.574a.9.9,0,0,1-.772,1l-47.956,6.177A.948.948,0,0,1,4950.735,2994.594Zm-7.574-66.7,8.348,64.8,46.184-5.949-8.346-64.8Z" transform="translate(-4941.268 -2920.057)" fill="#47327c" />
        </g>
        <g id="Group_654" data-name="Group 654" transform="translate(4950.686 2927.512)">
          <g id="Group_647" data-name="Group 647" transform="translate(0)">
            <g id="Group_641" data-name="Group 641">
              <path id="Path_3898" data-name="Path 3898" d="M4989.342,2983.3l-25.419,3.274a3.275,3.275,0,0,1-3.667-2.83l-5.42-42.083a3.274,3.274,0,0,1,2.829-3.666l25.42-3.274a3.275,3.275,0,0,1,3.666,2.829l1.385,10.752,2.652,20.582,1.384,10.75A3.275,3.275,0,0,1,4989.342,2983.3Z" transform="translate(-4953.915 -2933.806)" fill="#ffcc71" />
              <path id="Path_3899" data-name="Path 3899" d="M4963.239,2987.237a4.173,4.173,0,0,1-4.131-3.636l-5.421-42.083a4.174,4.174,0,0,1,3.6-4.667l25.419-3.273a4.168,4.168,0,0,1,4.667,3.6l5.421,42.083a4.172,4.172,0,0,1-3.6,4.666l-25.419,3.274A4.142,4.142,0,0,1,4963.239,2987.237Zm20.007-51.908a2.526,2.526,0,0,0-.309.02l-25.42,3.274a2.384,2.384,0,0,0-2.058,2.667l5.42,42.083a2.383,2.383,0,0,0,2.667,2.058l25.42-3.274h0a2.383,2.383,0,0,0,2.058-2.666l-5.42-42.084a2.384,2.384,0,0,0-2.357-2.078Z" transform="translate(-4953.652 -2933.543)" fill="#47327c" />
            </g>
            <path id="Path_3900" data-name="Path 3900" d="M4960.267,2988.231a.893.893,0,0,1-.113-1.779l7.082-.912a.893.893,0,1,1,.229,1.771l-7.082.912A.8.8,0,0,1,4960.267,2988.231Z" transform="translate(-4954.953 -2945.355)" fill="#47327c" />
            <path id="Path_3901" data-name="Path 3901" d="M4992.732,2984.059a.893.893,0,0,1-.113-1.779l6.827-.879a.893.893,0,0,1,.228,1.772l-6.827.879A.905.905,0,0,1,4992.732,2984.059Z" transform="translate(-4962.329 -2944.414)" fill="#47327c" />
            <g id="Group_646" data-name="Group 646" transform="translate(11.103 32.586)">
              <g id="Group_645" data-name="Group 645">
                <g id="Group_642" data-name="Group 642" transform="translate(0 1.632)">
                  <path id="Path_3902" data-name="Path 3902" d="M4978.872,2986.064l-4.692,3.606a2.636,2.636,0,0,1-4.22-1.757l-.762-5.96a2.636,2.636,0,0,1,3.643-2.761l5.448,2.309" transform="translate(-4968.282 -2978.084)" fill="#ff7d92" />
                  <path id="Path_3903" data-name="Path 3903" d="M4972.321,2990.851a3.56,3.56,0,0,1-1.366-.276,3.493,3.493,0,0,1-2.145-2.811l-.762-5.96a3.529,3.529,0,0,1,4.877-3.7l5.448,2.309a.893.893,0,1,1-.7,1.645l-5.448-2.309a1.743,1.743,0,0,0-2.409,1.825l.762,5.961a1.743,1.743,0,0,0,2.791,1.161l4.692-3.6a.893.893,0,1,1,1.088,1.417l-4.692,3.6A3.5,3.5,0,0,1,4972.321,2990.851Z" transform="translate(-4968.02 -2977.822)" fill="#47327c" />
                </g>
                <g id="Group_643" data-name="Group 643" transform="translate(9.113)">
                  <path id="Path_3904" data-name="Path 3904" d="M4981.551,2985.584l5.448,2.309a2.636,2.636,0,0,0,3.643-2.761l-.761-5.96a2.636,2.636,0,0,0-4.221-1.756l-4.692,3.605" transform="translate(-4980.075 -2975.972)" fill="#ff7d92" />
                  <path id="Path_3905" data-name="Path 3905" d="M4987.776,2988.738a3.555,3.555,0,0,1-1.388-.285l-5.448-2.309a.893.893,0,0,1,.7-1.645l5.448,2.309a1.743,1.743,0,0,0,2.409-1.825l-.762-5.961a1.743,1.743,0,0,0-2.791-1.161l-4.692,3.605a.893.893,0,1,1-1.088-1.417l4.692-3.605a3.529,3.529,0,0,1,5.651,2.351l.762,5.961a3.539,3.539,0,0,1-3.49,3.982Z" transform="translate(-4979.813 -2975.709)" fill="#47327c" />
                </g>
                <g id="Group_644" data-name="Group 644" transform="translate(7.042 4.03)">
                  <rect id="Rectangle_923" data-name="Rectangle 923" width="4.496" height="4.6" rx="1.731" transform="translate(0.735 1.303) rotate(-7.263)" fill="#ff7d92" />
                  <path id="Path_3906" data-name="Path 3906" d="M4979.605,2987.525a2.234,2.234,0,0,1-2.211-1.948l-.244-1.91a2.233,2.233,0,0,1,1.93-2.5l1.807-.229a2.232,2.232,0,0,1,2.5,1.93l.244,1.909a2.235,2.235,0,0,1-1.93,2.5h0l-1.806.23A2.256,2.256,0,0,1,4979.605,2987.525Zm1.978-1.134h0Zm-.413-3.682a.455.455,0,0,0-.057,0l-1.806.23a.444.444,0,0,0-.384.5l.243,1.91a.444.444,0,0,0,.5.384l1.807-.23a.445.445,0,0,0,.384-.5l-.244-1.91A.445.445,0,0,0,4981.17,2982.71Z" transform="translate(-4977.132 -2980.924)" fill="#47327c" />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_653" data-name="Group 653" transform="translate(7.206 7.433)">
            <g id="Group_648" data-name="Group 648">
              <circle id="Ellipse_51" data-name="Ellipse 51" cx="10.597" cy="10.597" r="10.597" transform="translate(0.553 21.736) rotate(-88.122)" fill="#fff" />
              <path id="Path_3907" data-name="Path 3907" d="M4974.46,2966.144a11.49,11.49,0,1,1,11.405-12.958h0a11.5,11.5,0,0,1-9.929,12.864A11.664,11.664,0,0,1,4974.46,2966.144Zm.028-21.2a9.908,9.908,0,0,0-1.259.081,9.667,9.667,0,1,0,1.259-.081Z" transform="translate(-4962.977 -2943.162)" fill="#47327c" />
            </g>
            <g id="Group_652" data-name="Group 652" transform="translate(6.785 3.147)">
              <path id="Path_3908" data-name="Path 3908" d="M4975.64,2962.9a2.907,2.907,0,0,1-2.876-2.536l-.066-.509a.893.893,0,0,1,1.772-.228l.066.51a1.12,1.12,0,0,0,1.254.968l2.617-.337a1.12,1.12,0,0,0,.967-1.254l-.234-1.818a1.12,1.12,0,0,0-1.254-.968l-2.618.338a2.906,2.906,0,0,1-3.253-2.511l-.234-1.819a2.906,2.906,0,0,1,2.511-3.254l2.617-.337a2.907,2.907,0,0,1,3.254,2.511l.066.509a.894.894,0,0,1-1.772.229l-.065-.51a1.119,1.119,0,0,0-1.254-.968l-2.618.338a1.12,1.12,0,0,0-.967,1.254l.234,1.818a1.12,1.12,0,0,0,1.254.968l2.617-.337a2.912,2.912,0,0,1,3.254,2.511l.233,1.819a2.91,2.91,0,0,1-2.511,3.253l-2.618.338A2.927,2.927,0,0,1,4975.64,2962.9Z" transform="translate(-4971.756 -2947.661)" fill="#47327c" />
              <g id="Group_651" data-name="Group 651" transform="translate(2.854)">
                <g id="Group_649" data-name="Group 649">
                  <path id="Path_3909" data-name="Path 3909" d="M4976.553,2950.66a.894.894,0,0,1-.885-.779l-.211-1.64a.893.893,0,1,1,1.771-.229l.212,1.641a.894.894,0,0,1-.772,1A1.025,1.025,0,0,1,4976.553,2950.66Z" transform="translate(-4975.449 -2947.234)" fill="#47327c" />
                </g>
                <g id="Group_650" data-name="Group 650" transform="translate(1.709 13.264)">
                  <path id="Path_3910" data-name="Path 3910" d="M4978.763,2967.824a.893.893,0,0,1-.885-.779l-.211-1.64a.893.893,0,1,1,1.771-.228l.211,1.64a.893.893,0,0,1-.771,1A.967.967,0,0,1,4978.763,2967.824Z" transform="translate(-4977.66 -2964.397)" fill="#47327c" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
