import styled from 'styled-components';

export const ShippingFormStyled = styled.div`
  .form_title {
    margin-bottom: 5px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLUE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S25};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_wrap {
    margin-bottom: 12px;
    &.mb_34 {
      margin-bottom: 34px;
    }
  }
`;
