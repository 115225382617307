import React from 'react';
import { ActivityDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { toast } from 'react-toastify';
import { Cards, Displays, Forms, Icons, Modals } from 'components';

class ActivityDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { router } = this.props;
    let resArr = await Promise.all([
      userService.GET_CAMPAIGN_DETAIL(router?.params?.id),
      userService.GET_CAMPAIGN_HISTORY_BY_CAMPAIGN_ID(router?.params?.id),
    ]);
    if (resArr) {
      const renderData = {
        ...resArr[0].data,
        ...resArr[1].data,
        isRegisted: resArr[1].status === 200 ? true : false,
      };
      this.setState({
        isLoading: false,
        data: renderData,
        renderForm: this.renderForm(renderData),
      });
    }
  };

  renderForm = (e) => {
    switch (e.type) {
      case '1':
        return (
          <Forms.ActivityAcceptType1Form
            data={e}
            onSubmit={this.handleSubmit}
          />
        );
      case '2':
        return (
          <Forms.ActivityAcceptType2Form
            data={e}
            onSubmit={this.handleSubmit}
          />
        );
      case '3':
        return (
          <Forms.ActivityAcceptType3Form
            data={e}
            onSubmit={this.handleSubmit}
          />
        );
      default:
        break;
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleSubmit = async (values) => {
    const { data } = this.state;
    let params = {
      campaign_id: data._id,
    };
    if (data.type === '2') {
      params.campaign_serial_no = values.campaign_serial_no;
    }
    let res = await userService.POST_JOIN_CAMPAIGN(params);
    if (res && res.status === 200) {
      if (data.type !== '3') {
        this.handleNextStep();
      } else {
        let filesTemp = [
          ...values.campaign_document
            .filter((e) => e.img_path[0].isNewUpload)
            .map((e) => e.img_path[0]),
        ];
        if (filesTemp.length === 0) {
          toast.error('กรุณาอัพไฟล์');
        } else {
          this.handleUploadContentMedia(res.data._id, filesTemp);
        }
      }
    } else {
      toast.error('เข้าร่วมกิจกรรม ไม่สำเร็จ');
    }
  };

  handleUploadContentMedia = (campaignHistoryID, files) => {
    const { data } = this.state;
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('campaign_id', data._id);
            bodyFormData.set('campaign_history_id', campaignHistoryID);
            bodyFormData.set('type', 'img_path');
            bodyFormData.append('image', e);
            let res = await userService.POST_UPLOAD_CAMPAGIN(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      this.handleNextStep();
    });
  };

  handleNextStep = () => {
    this.setState({
      isShowModal: true,
      renderModal: (
        <Cards.ProcessStatus
          icon={<Icons.Success />}
          description={'ลงทะเบียนสำเร็จ'}
          btnLabel="ตกลง"
          onClick={() => {
            const body = document.body;
            body.style.overflow = 'auto';
            this.props.router.navigate(ROUTE_PATH.ACTIVITY);
          }}
        />
      ),
    });
  };

  render() {
    const { renderForm, isLoading, isShowModal, renderModal, data } =
      this.state;
    return (
      <ActivityDetailContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="activity_item" onClick={() => this.handleClick()}>
              <Cards.ActivityDetail img={IMG_PATH + data.campaign_img} />
            </div>
            <div className="description_label">{data.description}</div>
            {renderForm}
          </>
        )}
        <Modals.BodyEmpty
          isForceAction
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivityDetailContainerStyled>
    );
  }
}

export default withRouter(ActivityDetailContainer);
