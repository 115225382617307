import styled from 'styled-components';

export const HomeContainerStyled = styled.div`
  padding: 20px 8px 173px 8px;
  .home_body_sec {
    .activity_item {
      margin-bottom: 10px;
    }
  }
  .search_wrap {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 75px;
    margin: auto;
    width: 100%;
    max-width: 480px;
    background: white;
    z-index: 99;
  }
`;
