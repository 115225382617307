import styled from 'styled-components';

export const TopTabStyled = styled.div`
  .top_tap_body_layout {
    border-radius: 1rem;
    padding: 20px 14px 14px 14px;
  }
  .top_tap_header_layout {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 87px;
    margin: auto;
    width: 100%;
    max-width: calc(480px - 28px);
    border-radius: 8px;
    width: 100%;
    padding: 8px 0px;
    display: flex;
    background: ${({ theme }) => theme.COLORS.BLUE_4};
    .header_item {
      border-right: 1px solid ${({ theme }) => theme.COLORS.WHITE_1};
      flex: 1;
      height: 26px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      cursor: pointer;
      position: relative;
      &.acitve {
        color: ${({ theme }) => theme.COLORS.ORANGE_1};
      }
      &:last-child {
        border-right: 0px;
      }
      .img_show {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        &.show {
          display: block;
        }
        &.reward_eletronic {
          top: -82px;
        }
        &.reward_card {
          top: -57px;
        }
        &.reward_etc {
          top: -58px;
        }
      }
    }
  }
  .theme_standard {
  }
`;
