import styled from 'styled-components';
import { IMG_PATH } from 'utils/constants/imgPath';

export const MenuBarWidgetStyled = styled.div`
  height: 75px;
  display: flex;
  background-color: ${({ theme }) => theme.COLORS.BLUE_4};
  background-position: center;
  background-size: cover;
  background-image: ${({ bg }) => (bg ? `url(${IMG_PATH + bg})` : '')};
  .menu_wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      .menu_item {
        .mi_label {
          color: ${({ theme }) => theme.COLORS.ORANGE_1};
        }
      }
    }
    .menu_item {
      padding-top: 6px;
      .menu_img_figture {
        margin: auto;
        width: 36px;
        height: 36px;
        text-align: center;
        .menu_img {
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      }
      .mi_label {
        margin-top: 6px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      }
    }
  }
`;
