import styled from 'styled-components';

export const ExchangeRewardStep3Styled = styled.div`
  width: 100%;
  max-height: calc(100vh - 123px - 108px);
  overflow: auto;
  .body_exchange {
    border-radius: 20px;
    margin: auto;
    max-width: 354px;
    padding: 32px 14px 23px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    position: relative;
    .close {
      display: flex;
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 12px;
    }
    .logo {
      width: 50%;
      height: auto;
    }
    .tab_zone {
      width: 100%;
      margin-top: 20px;
    }
    .code_label {
      margin-bottom: 5px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S17};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
    .product_label {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
    .btn_layout {
      margin-top: 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn_body {
        width: 47%;
      }
    }
    .btn_link {
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.BLUE_2};
    }
  }
`;
