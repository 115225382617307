import React from 'react';
import { ActivityAcceptType1FormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ActivityAcceptType1Form = ({ data, onSubmit }) => {
  const schema = yup.object().shape({
    is_agreement: yup
      .bool()
      .nullable()
      .oneOf([true], 'Please accept condition'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <ActivityAcceptType1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_checkbox_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label="ยอมรับเงื่อนไขกิจกรรม"
                disabled={data?.isRegisted}
                errors={errors.is_agreement?.message}
              />
            )}
            name="is_agreement"
            defaultValue={data?.isRegisted || false}
          />
        </div>
        {data?.isRegisted ? (
          <Buttons.BgStandard
            type="button"
            disabled
            txtColor={data.btn_joined_text_color}
            bgColor={data.btn_joined_button_color}
            label={data.btn_joined_label || 'คุณเข้าร่วมกิจกรรมแล้ว'}
          />
        ) : (
          <Buttons.BgStandard
            type="submit"
            bgColor={data?.btn_join_button_color}
            txtColor={data?.btn_join_text_color}
            label={data?.btn_join_label || 'ยืนยันเข้าร่วมกิจกรรม'}
          />
        )}
      </form>
    </ActivityAcceptType1FormStyled>
  );
};

ActivityAcceptType1Form.propTypes = {};

export default ActivityAcceptType1Form;
