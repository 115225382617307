import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ActivityStyled } from './styled';

const Activity = ({ theme_standard, img, isRegisted }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ActivityStyled img={img}>
      <div className={customClass}>
        {isRegisted && (
          <div className="activity_registed_wrap">
            <img alt="registed stamp" src="/assets/images/icons/registed.png" />
          </div>
        )}
      </div>
    </ActivityStyled>
  );
};

Activity.propTypes = {};

export default Activity;
