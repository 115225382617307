import React from 'react';
import { ExchangeShippingStepStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Forms } from 'components';

class ExchangeShippingStep extends React.Component {
  render() {
    const { customerProfile, onSubmit } = this.props;
    return (
      <ExchangeShippingStepStyled>
        <div className="ex_wrap">
          <Forms.ShippingForm
            initialValues={customerProfile}
            onSubmit={onSubmit}
          />
        </div>
      </ExchangeShippingStepStyled>
    );
  }
}

export default withRouter(ExchangeShippingStep);
