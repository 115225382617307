import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ProcessStatusStyled } from './styled';
import { Buttons } from 'components';

const ProcessStatus = ({
  theme_standard,
  icon,
  title,
  description,
  btnLabel,
  onClick,
  btnTxtColor,
  btnBgColor,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ProcessStatusStyled>
      <div className={customClass}>
        {icon}
        {title && <div className="ps_title">{title}</div>}
        <div className="ps_label">{description}</div>
        <div className="ps_btn_wrap">
          <Buttons.BgStandard
            theme_stadard_btn
            bgColor={btnBgColor}
            txtColor={btnTxtColor}
            label={btnLabel}
            onClick={onClick}
          />
        </div>
      </div>
    </ProcessStatusStyled>
  );
};

ProcessStatus.propTypes = {};

export default ProcessStatus;
