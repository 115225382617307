import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { RewardStyled } from './styled';
import { RenderCommaMoney } from 'utils/functions/formatMoney';

const Reward = ({ theme_standard, img, point, expire, amount }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <RewardStyled img={img}>
      <div className={customClass}>
        <div className="reward_container">
          <div className="r_point">{RenderCommaMoney(point)}</div>
          <div className="point_wrap">
            <img
              className="poin_img"
              alt="misumi coin"
              src="/assets/images/icons/coin.png"
            />
          </div>
        </div>
      </div>
    </RewardStyled>
  );
};

Reward.propTypes = {};

export default Reward;
