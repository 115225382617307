import styled from 'styled-components';

export const LogoStyled = styled.div`
  .header_img {
    width: 100%;
    height: auto;
  }

  .theme_standard {
  }
`;
