import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ActivityDetailStyled } from './styled';

const ActivityDetail = ({ theme_standard, img }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ActivityDetailStyled img={img}>
      <div className={customClass}>
        <img className="ad_campaign_img" alt="misumi campaign" src={img} />
      </div>
    </ActivityDetailStyled>
  );
};

ActivityDetail.propTypes = {};

export default ActivityDetail;
