import React, { useState, useEffect } from 'react';
import { UploadStyled } from './styled';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Icons } from 'components';

const Upload = ({
  theme_standard,
  onChange,
  acceptType,
  value,
  maxMBFileSizeWarning,
  errors,
  inputImgUpload,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_files, _setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case 'cover':
        return 'image/*';
      case 'audio':
        return '.mp3,audio/mpeg3';
      case 'video':
        return 'video/mp4';
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      let fileTemp = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      _setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      _setFiles(value);
    }
  }, [value]);

  // const onClickRemove = (index, data) => {
  //   if (data.isNewUpload) {
  //     let filesTemp = _files;
  //     filesTemp.splice(index, 1);
  //     _setFiles([...filesTemp]);
  //   } else {
  //     let filesTemp = _files;
  //     filesTemp[index].isDelete = true;
  //     _setFiles([...filesTemp]);
  //   }
  // };

  return (
    <UploadStyled>
      <div className={customClass}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="input_upload_container">
            <img
              className="upload_img"
              alt="upload"
              src={
                inputImgUpload
                  ? IMG_PATH + inputImgUpload
                  : '/assets/images/icons/upload.png'
              }
            />
            {_files && _files.length > 0 && (
              <div className="icon_uploaded">
                <Icons.Success width={40} height={40} />
              </div>
            )}
          </div>
        </div>
        {/* <aside>
          {_files &&
            _files.length > 0 &&
            _files.map((e, i) =>
              e.isNewUpload ? (
                <img
                  key={i}
                  className="img_preview"
                  src={e.preview}
                  alt="upload img"
                />
              ) : (
                // <Cards.PreviewInput
                //   key={i}
                //   type={acceptType}
                //   src={e.preview}
                //   name={e.name}
                //   onClickRemove={() => onClickRemove(i, e)}
                //   svg={svg}
                //   theme_left_right={theme_left_right}
                // />
                !e.isDelete && (
                  <img
                    key={i}
                    className="img_preview"
                    src={e.media_uri}
                    alt="upload img"
                  />
                  // <Cards.PreviewInput
                  //   key={i}
                  //   type={acceptType}
                  //   src={e.media_uri}
                  //   name={e.name}
                  //   onClickRemove={() => onClickRemove(i, e)}
                  //   svg={svg}
                  //   theme_left_right={theme_left_right}
                  // />
                )
              )
            )}
        </aside> */}
        {errors && <div className="errors">{errors}</div>}
      </div>
    </UploadStyled>
  );
};

Upload.propTypes = {};

export default Upload;
