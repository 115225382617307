import styled from 'styled-components';

export const LoadingStyled = styled.div`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .theme_standard_loading {
  }
`;
