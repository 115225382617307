import ActivityAcceptType1Form from './ActivityAcceptType1Form';
import ActivityAcceptType2Form from './ActivityAcceptType2Form';
import ActivityAcceptType3Form from './ActivityAcceptType3Form';
import LoginForm from './LoginForm';
import ProfileForm from './ProfileForm';
import RegisterForm from './RegisterForm';
import ShippingForm from './ShippingForm';

const EXPORT = {
  ActivityAcceptType1Form,
  ActivityAcceptType2Form,
  ActivityAcceptType3Form,
  LoginForm,
  ProfileForm,
  RegisterForm,
  ShippingForm,
};

export default EXPORT;
