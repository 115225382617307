import React from 'react';
import { connect } from 'react-redux';
import { RewardElectronicContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import {
  ExchangeRewardStep1,
  ExchangeRewardStep2,
  ExchangeRewardStep3,
} from '../Steps';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { Cards, Displays, Modals } from 'components';

class RewardElectronicContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_REWARD_LIST(
      `?pagination=false&is_active=true&type=1&sort=sequence`
    );
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleRenderModal = (key, e) => {
    const { dataSelected } = this.state;
    switch (key) {
      case 'step1':
        this.setState({
          isShowModal: true,
          dataSelected: e,
          renderModal: (
            <ExchangeRewardStep1
              data={e}
              onCancel={this.handleCloseModal}
              onAccept={() => this.handleRenderModal('step2')}
            />
          ),
        });
        break;
      case 'step2':
        this.setState({
          isShowModal: true,
          renderModal: (
            <ExchangeRewardStep2
              data={dataSelected}
              amount={1}
              onCancel={() => this.handleRenderModal('step1', dataSelected)}
              onAccept={async () => {
                let params = {
                  reward_id: dataSelected._id,
                  redeem_amount: 1,
                  is_ecopon: true,
                };
                let res = await userService.POST_REDEEM_REWARD(params);
                if (res && res.status === 200) {
                  let resWallet = await userService.GET_MY_WALLET();
                  if (resWallet && resWallet.status === 200) {
                    this.fetchData();
                    this.props.setReduxWallet(resWallet.data);
                    this.handleRenderModal('step3', res.data);
                  }
                } else {
                  this.setState({
                    renderModal: (
                      <Cards.MusumiStatus
                        title={'ขออภัย!'}
                        description={
                          'คะแนนของคุณไม่เพียงพอต่อการแลกของรางวัลชิ้นนี้ หรือสินค้าหมดอายุ'
                        }
                        btnLabel="ย้อนกลับ"
                        onClick={() => {
                          this.setState({
                            isShowModal: false,
                          });
                        }}
                      />
                    ),
                  });
                }
              }}
            />
          ),
        });
        break;
      case 'step3':
        this.setState({
          isShowModal: true,
          renderModal: (
            <ExchangeRewardStep3
              data={{ ...dataSelected, code: e.code }}
              onCancel={this.handleCloseModal}
              // onAccept={() => this.handleRenderModal('step3')}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { isShowModal, renderModal, isLoading, data } = this.state;
    return (
      <RewardElectronicContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="reward_layout">
            {data?.docs &&
              data.docs.map((e, i) => (
                <div
                  key={i}
                  className="reward_wrap"
                  onClick={() => this.handleRenderModal('step1', e)}
                >
                  <Cards.Reward
                    img={IMG_PATH + e.img_path}
                    point={e.redeem_point}
                    amount={e.total_amount - e.redeem_amount}
                    expire={moment(e.expired_at).format('DD/MM/YY')}
                  />
                </div>
              ))}
          </div>
        )}
        <Modals.BodyEmpty
          isForceAction
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardElectronicContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RewardElectronicContainer));
