import styled from 'styled-components';

export const PolicyStyled = styled.div`
  border-radius: 10px;
  padding: 20px 24px;
  max-width: 380px;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .policy_title {
    margin-bottom: 12px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
  }
  .policy_label {
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_SEMIBOLD};
    &.mb_18 {
      margin-bottom: 18px;
    }
  }
  .p_action_row {
    display: flex;
    column-gap: 24px;
    .p_btn_col {
      flex: 1;
    }
  }
`;
