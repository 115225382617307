import styled from 'styled-components';

export const RewardContainerStyled = styled.div`
  padding: 0px 0px 125px 0px;
  .reward_layout {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    .reward_wrap {
      flex-basis: calc(50% - 5px);
    }
  }
`;
