import styled from 'styled-components';

export const BgStandardStyled = styled.div`
  .btn {
    border-radius: 8px;
    width: 100%;
    height: 48px;
    background: ${({ theme, bgColor, disabled }) =>
      !disabled
        ? bgColor
          ? bgColor
          : theme.COLORS.ORANGE_1
        : bgColor
        ? bgColor
        : theme.COLORS.GRAY_4};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ theme, disabled, txtColor }) =>
      disabled
        ? txtColor
          ? txtColor
          : theme.COLORS.WHITE_1
        : txtColor
        ? txtColor
        : theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S25};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }

  .theme_stadard_btn {
    .btn {
      height: 44px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    }
  }
  .theme_cancel_btn {
    .btn {
      height: 44px;
      background: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.GRAY_2 : theme.COLORS.GRAY_2};
      color: ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.WHITE_1 : theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    }
  }

  .theme_search_btn {
    .btn {
      height: 42px;
    }
  }
`;
