import styled from 'styled-components';
import { IMG_PATH } from 'utils/constants/imgPath';

export const SystemLayoutStyled = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  min-height: 100vh;
  background-color: ${({ theme }) => theme.COLORS.BLUE_1};
  background-image: ${({ bg }) =>
    bg ? `url(${IMG_PATH + bg})` : 'url(/assets/images/bg/bg-white.jpg)'};
  background-position: center;
  background-size: cover;
  .header_mainlayout {
    position: fixed;
    top: 0px;
    margin: auto;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.MOBILE};
    z-index: 99;
  }
  .body_mainlayout {
    padding-top: 123px;
  }
  .menu_mainlayout {
    position: fixed;
    bottom: 0px;
    margin: auto;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.MOBILE};
    z-index: 99;
  }
`;
