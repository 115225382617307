import { ExchangeRewardStep2Styled } from './styled';
import { RenderCommaMoney } from 'utils/functions/formatMoney';
import { Buttons } from 'components';

const ExchangeRewardStep2 = ({ data, amount, onCancel, onAccept }) => {
  return (
    <ExchangeRewardStep2Styled>
      <div className="body_exchange">
        <img className="logo" alt="logo" src="/assets/images/logos/logo.png" />
        <div className="small_text">ยืนยันการแลกคะแนน</div>
        <div className="title">
          {RenderCommaMoney(data.redeem_point * amount)} คะแนน
        </div>
        <div className="small_text">เมื่อแลกคะแนนแล้วจะไม่สามารถ</div>
        <div className="small_text no_mt">
          เปลี่ยนแปลงหรือยกเลิกของรางวัลได้
        </div>
        {data.type === '1' && (
          <div className="small_text red">
            บัตรกำนัลมีอายุ 24 ชม. หลังจากกดยืนยัน
          </div>
        )}
        <div className="btn_layout">
          <div className="btn_body">
            <Buttons.BgStandard
              theme_cancel_btn
              label={'ย้อนกลับ'}
              onClick={onCancel}
            />
          </div>
          <div className="btn_body">
            <Buttons.BgStandard
              theme_stadard_btn
              label={'ยืนยัน'}
              onClick={onAccept}
            />
          </div>
        </div>
      </div>
    </ExchangeRewardStep2Styled>
  );
};

export default ExchangeRewardStep2;
