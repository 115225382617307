import Avatar from './Avatar';
import { default as Loading } from './Loading';
import { default as BGLoading } from './BGLoading';
import Status from './Status';

const EXPORT = {
  Avatar,
  Loading,
  BGLoading,
  Status,
};

export default EXPORT;
