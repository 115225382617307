import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Mainlayouts } from 'components';
import systemRoutes from './systemRoutes';
import SplashContainer from 'containers/SplashContainer';
import LoginContainer from 'containers/LoginContainer';
import RegisterContainer from 'containers/RegisterContainer';

const Navigations = () => {
  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route path={ROUTE_PATH.SPLASH} element={<SplashContainer />} />
        <Route path={ROUTE_PATH.REGISTER} element={<RegisterContainer />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route path="*" element={<SplashContainer />} />
      </Route>
      <Route path={ROUTE_PATH.SYSTEM} element={<Mainlayouts.SystemLayout />}>
        {systemRoutes &&
          systemRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i} path={e.path} element={e.element}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      // index={f.index}
                      index
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
      </Route>
    </Routes>
  );
};

export default Navigations;
