import styled from 'styled-components';

export const ProcessStatusStyled = styled.div`
  border-radius: 10px;
  width: 100%;
  max-width: 371px;
  padding: 40px 16px 16px 16px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  text-align: center;
  .ps_title {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S25};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .ps_label {
    margin-top: 10px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
  }
  .ps_btn_wrap {
    margin: auto;
    max-width: 153px;
  }

  .theme_standard {
    .ps_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
  }
`;
