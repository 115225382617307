import styled from 'styled-components';

export const ActivityAcceptType1FormStyled = styled.div`
  .input_checkbox_wrap {
    margin-top: 100px;
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
  }
`;
