import InputAmout from './InputAmout';
import InputCheckBox from './InputCheckBox';
import InputSearch from './InputSearch';
import TextArea from './TextArea';
import TextField from './TextField';
import Upload from './Upload';

const EXPORT = {
  InputAmout,
  InputCheckBox,
  InputSearch,
  TextArea,
  TextField,
  Upload,
};

export default EXPORT;
