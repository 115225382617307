import { useState, useEffect } from 'react';
import { SystemLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { userService } from 'apiServices';
import { useSelector, useDispatch } from 'react-redux';
import { setReduxConfigSystem } from 'store/reducers/configSystemReducer';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { NavbarWidget, MenuBarWidget, PopupWidget } from 'widgets';
import { Displays } from 'components';

const SystemLayout = () => {
  let dispatch = useDispatch();
  const { configSystemRedux } = useSelector((state) => state);

  const [_isLoading, _setIsLoading] = useState(true);
  const [_popupDetail, _setPopupDetail] = useState();

  useEffect(() => {
    fetchConfigSystem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchConfigSystem = async () => {
    let resArr = await Promise.all([
      userService.GET_CONFIG_SYSTEM(),
      userService.GET_MY_PROFILE(),
      userService.GET_MY_WALLET(),
      userService.GET_MY_POPUP_BANNER_LIST(),
    ]);
    if (resArr) {
      _renderPopup(resArr[2]?.data?.point, resArr[3].data.docs);
      dispatch(setReduxConfigSystem(resArr[0].data.docs));
      dispatch(setReduxAuthen(resArr[1].data));
      dispatch(setReduxWallet(resArr[2].data));
      _setIsLoading(false);
    }
  };

  const _renderPopup = (point, e) => {
    const isShowAppFist = localStorage.getItem('isShowedAppFirst');
    const isShowOpenedFist = sessionStorage.getItem('isShowedOpenedFirst');
    const displayType1 = isShowAppFist
      ? []
      : e.filter((f) => f.display_type === '1');
    const displayType2 = isShowOpenedFist
      ? []
      : e.filter((f) => f.display_type === '2');
    // process 1 => check has data,  has data show
    if (
      e.length === 0 ||
      (isShowAppFist && isShowOpenedFist) ||
      (displayType1.length === 0 && displayType2.length === 0)
    ) {
      _setPopupDetail(false);
    } else {
      // process 2 => check type by display type prioriry  1, 2
      let tempData = null;
      if (displayType1.length > 0) {
        tempData = displayType1.reduce(
          (result, data) => {
            if (data.type === '1') {
              result.type1.push({ ...data, store: 'localStorage' });
            }
            if (data.type === '2') {
              result.type2.push({ ...data, store: 'localStorage' });
            }
            return result;
          },
          {
            type1: [],
            type2: [],
          }
        );
      } else {
        if (displayType2.length > 0) {
          tempData = displayType2.reduce(
            (result, data) => {
              if (data.type === '1') {
                result.type1.push({ ...data, store: 'sessionStorage' });
              }
              if (data.type === '2') {
                result.type2.push({ ...data, store: 'sessionStorage' });
              }
              return result;
            },
            {
              type1: [],
              type2: [],
            }
          );
        }
      }
      // check condition priority type 2  then  type 1
      if (tempData && tempData.type2.length > 0) {
        let resPassCondition = tempData.type2.find(
          (g) => point >= g.start_point && point <= g.end_point
        );
        if (resPassCondition) {
          _setPopupDetail(resPassCondition);
        } else {
          if (tempData.type1.length > 0) {
            _setPopupDetail(tempData.type1[0]);
          }
        }
      } else {
        if (tempData.type1.length > 0) {
          _setPopupDetail(tempData.type1[0]);
        }
      }
    }
  };

  const _handleClickBg = () => {
    _setPopupDetail(false);
    if (_popupDetail && _popupDetail.store === 'localStorage') {
      localStorage.setItem('isShowedAppFirst', 'true');
    }
    if (_popupDetail && _popupDetail.store === 'sessionStorage') {
      sessionStorage.setItem('isShowedOpenedFirst', 'true');
    }
  };

  const _handleClickPopup = (e) => {
    switch (e.action_type) {
      case '1':
        _handleClickBg();
        break;
      case '2':
        if (e.url) {
          window.open(e.url, '_blank', 'noreferrer');
        }
        _handleClickBg();
        break;
      default:
        break;
    }
  };

  return (
    <SystemLayoutStyled bg={configSystemRedux?.bg_img}>
      <div className="header_mainlayout">
        <NavbarWidget />
      </div>
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading /> : <Outlet />}
      </div>
      <div className="menu_mainlayout">
        <MenuBarWidget />
      </div>
      {_popupDetail && (
        <PopupWidget
          src={_popupDetail.img_path}
          onClickBg={_handleClickBg}
          onClickPopup={() => _handleClickPopup(_popupDetail)}
        />
      )}
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
