import CloseX from './CloseX';
import Fail from './Fail';
import Loading from './Loading';
import Success from './Success';
import MenuActivity from './MenuActivity';
import MenuHistory from './MenuHistory';
import MenuProfile from './MenuProfile';
import MenuReward from './MenuReward';
import Reward1 from './Reward1';

const EXPORT = {
  Reward1,
  CloseX,
  Fail,
  Loading,
  Success,
  MenuActivity,
  MenuHistory,
  MenuProfile,
  MenuReward,
};

export default EXPORT;
