import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputAmoutStyled } from './styled';

const InputAmout = ({ theme_standard, onChange, max }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValue] = useState(1);

  const _handleClick = (key) => {
    switch (key) {
      case 'decrement':
        if (_value > 1) {
          _setValue(_value - 1);
          onChange && onChange(_value - 1);
        }
        break;
      case 'increment':
        if (_value + 1 <= max) {
          _setValue(_value + 1);
          onChange && onChange(_value + 1);
        }
        break;
      default:
        break;
    }
  };

  return (
    <InputAmoutStyled>
      <div className={customClass}>
        <div className="input_amount_container">
          <div className="ia_control" onClick={() => _handleClick('decrement')}>
            -
          </div>
          <div className="ia_value">{_value}</div>
          <div className="ia_control" onClick={() => _handleClick('increment')}>
            +
          </div>
        </div>
      </div>
    </InputAmoutStyled>
  );
};

InputAmout.propTypes = {};

export default InputAmout;
