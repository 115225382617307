import { PopupWidgetStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Icons } from 'components';

const PopupWidget = ({ src, onClickBg, onClickPopup }) => {
  return (
    <PopupWidgetStyled>
      <div className="popup_bg" onClick={onClickBg} />
      <div className="popup_img_figture">
        <div className="popup_close" onClick={onClickBg}>
          <Icons.CloseX color="red" />
        </div>
        <img
          className="popup_img"
          alt="misumi popup"
          src={IMG_PATH + src}
          onClick={onClickPopup}
        />
      </div>
    </PopupWidgetStyled>
  );
};

export default PopupWidget;
