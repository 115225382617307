import React from 'react';
import { LoginContainerStyled } from './styled';
import { connect } from 'react-redux';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { Forms } from 'components';

class LoginContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      let resProfile = await userService.GET_MY_PROFILE();
      if (resProfile && resProfile.status === 200) {
        this.props.setReduxAuthen(resProfile.data);
        toast.success('Log in success');
        this.props.router.navigate(ROUTE_PATH.ACTIVITY);
      }
    } else {
      toast.error('Login failed');
    }
  };

  render() {
    return (
      <LoginContainerStyled>
        <Forms.LoginForm onSubmit={this.handleSubmit} />
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
