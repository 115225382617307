import styled from 'styled-components';

export const StatusStyled = styled.div`
  .status_label {
    border-radius: 6px;
    padding: 3px 7px;
    width: fit-content;
    background: ${({ theme, color }) => (!color ? theme.COLORS.BLUE_5 : color)};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }

  .theme_standard {
  }
`;
