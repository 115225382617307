import apiService from '../apiService';

const apiPath = '/api/v1';

export const userService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  POST_LINE_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login/line`, params);
  },
  POST_CHECK_TOKEN: () => {
    return apiService.get(`${apiPath}/login/check_token`);
  },

  GET_CONFIG_SYSTEM: () => {
    return apiService.get(`${apiPath}/config`);
  },
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPath}/customer/me`);
  },
  GET_MY_WALLET: () => {
    return apiService.get(`${apiPath}/wallet/me`);
  },
  GET_MY_POINT_HISTORY: () => {
    return apiService.get(`${apiPath}/upload-point/me`);
  },

  POST_REGISTER_CUSTOMER: (params) => {
    return apiService.post(`${apiPath}/customer/regis-line`, params);
  },
  PUT_MY_PROFILE_UPDATE: (params) => {
    return apiService.put(`${apiPath}/customer/me`, params);
  },

  // CAMPAIGN
  GET_CAMPAIGN_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/campaign${stringQuery ? stringQuery : ''}`
    );
  },
  GET_CAMPAIGN_DETAIL: (id) => {
    return apiService.get(`${apiPath}/campaign/${id}`);
  },
  GET_MY_CAMPAIGN_HISTORY: () => {
    return apiService.get(`${apiPath}/campaign-history/me`);
  },
  GET_CAMPAIGN_HISTORY_BY_CAMPAIGN_ID: (id) => {
    return apiService.get(`${apiPath}/campaign-history/campaign/${id}`);
  },
  POST_JOIN_CAMPAIGN: (params) => {
    return apiService.post(`${apiPath}/campaign-history/join`, params);
  },
  POST_UPLOAD_CAMPAGIN: (params) => {
    return apiService.post_formdata(
      `${apiPath}/campaign-history/upload`,
      params
    );
  },

  // REWARD
  GET_REWARD_LIST: (stringQuery) => {
    return apiService.get(`${apiPath}/reward${stringQuery ? stringQuery : ''}`);
  },
  GET_MY_REWARD_HISTORY: () => {
    return apiService.get(`${apiPath}/reward-history/me`);
  },
  POST_REDEEM_REWARD: (params) => {
    return apiService.post(`${apiPath}/reward-history/redeem`, params);
  },

  // BANNER
  GET_MY_POPUP_BANNER_LIST: () => {
    return apiService.get(`${apiPath}/popup-banner/mycondition`);
  },
};
