import React from 'react';

export default function renderIcon({
  color = '#fff',
  width = 35.73,
  height = 35.536,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.73 35.536"
    >
      <g id="Group_577" data-name="Group 577" transform="translate(0 0)">
        <path
          id="Path_3606"
          data-name="Path 3606"
          d="M905.693,3091.748a.451.451,0,0,1-.459-.45c0-3.494-2.282-6.321-5.088-6.321h-.032c-2.806,0-5.088,2.826-5.088,6.3a.468.468,0,0,1-.46.469.451.451,0,0,1-.459-.45v-.02c0-3.981,2.695-7.22,6.007-7.22h.032c3.312,0,6.007,3.239,6.007,7.22A.468.468,0,0,1,905.693,3091.748Z"
          transform="translate(-883.466 -3084.058)"
          fill={color}
        />
        <path
          id="Path_3607"
          data-name="Path 3607"
          d="M891.335,3131.176H868a.459.459,0,0,1,0-.919h23.332a.459.459,0,0,1,0,.919Z"
          transform="translate(-863.006 -3119.644)"
          fill={color}
        />
        <path
          id="Path_3608"
          data-name="Path 3608"
          d="M851.044,3123.244a.459.459,0,0,1-.122-.9l1.583-.437a.46.46,0,0,1,.244.886l-1.583.437A.462.462,0,0,1,851.044,3123.244Z"
          transform="translate(-849.942 -3113.197)"
          fill={color}
        />
        <path
          id="Path_3609"
          data-name="Path 3609"
          d="M864.669,3097.46a.46.46,0,0,1-.388-.212l-.925-1.448a.459.459,0,1,1,.774-.495l.925,1.448a.46.46,0,0,1-.387.707Z"
          transform="translate(-859.724 -3092.558)"
          fill={color}
        />
        <path
          id="Path_3610"
          data-name="Path 3610"
          d="M850.557,3106.481a.459.459,0,0,1-.226-.06l-2.309-1.31a.459.459,0,1,1,.453-.8l2.309,1.31a.46.46,0,0,1-.227.86Z"
          transform="translate(-847.788 -3099.612)"
          fill={color}
        />
        <path
          id="Path_3611"
          data-name="Path 3611"
          d="M992.021,3163.059a.462.462,0,0,1-.122-.017l-1.515-.418a.459.459,0,1,1,.244-.886l1.515.418a.459.459,0,0,1-.122.9Z"
          transform="translate(-957.365 -3143.88)"
          fill={color}
        />
        <path
          id="Path_3612"
          data-name="Path 3612"
          d="M981.1,3138.391a.454.454,0,0,1-.247-.073.459.459,0,0,1-.14-.634l.885-1.386a.459.459,0,1,1,.774.495l-.885,1.385A.459.459,0,0,1,981.1,3138.391Z"
          transform="translate(-950.118 -3124.134)"
          fill={color}
        />
        <path
          id="Path_3613"
          data-name="Path 3613"
          d="M990.159,3147.022a.46.46,0,0,1-.227-.859l2.209-1.253a.459.459,0,0,1,.453.8l-2.209,1.253A.453.453,0,0,1,990.159,3147.022Z"
          transform="translate(-957.098 -3130.884)"
          fill={color}
        />
        <path
          id="Path_3614"
          data-name="Path 3614"
          d="M938.131,3142.208a.454.454,0,0,1-.459-.453c0-2.082-1.344-3.765-3-3.765-1.673,0-3.017,1.683-3.017,3.752a.465.465,0,0,1-.459.466.454.454,0,0,1-.459-.453v-.012c0-2.576,1.757-4.671,3.916-4.671,2.179,0,3.936,2.1,3.936,4.671A.465.465,0,0,1,938.131,3142.208Z"
          transform="translate(-911.682 -3124.892)"
          fill={color}
        />
        <path
          id="Path_3615"
          data-name="Path 3615"
          d="M928.445,3166.773H913.281a.46.46,0,0,1,0-.919h15.165a.46.46,0,0,1,0,.919Z"
          transform="translate(-895.521 -3155.096)"
          fill={color}
        />
        <path
          id="Path_3616"
          data-name="Path 3616"
          d="M928.1,3174.628H913.625a.8.8,0,0,1-.8-.8v-17.713a.605.605,0,0,1,.6-.6h14.821a.658.658,0,0,1,.657.657v17.66A.8.8,0,0,1,928.1,3174.628Zm-.115-.8h0Zm-14.246-.115h14.246v-17.284H913.74Z"
          transform="translate(-897.881 -3139.093)"
          fill={color}
        />
        <path
          id="Path_3617"
          data-name="Path 3617"
          d="M941.22,3212.6a.922.922,0,1,1,.922-.922A.923.923,0,0,1,941.22,3212.6Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,941.22,3211.211Z"
          transform="translate(-919.045 -3181.646)"
          fill={color}
        />
        <path
          id="Path_3618"
          data-name="Path 3618"
          d="M955,3212.6a.922.922,0,1,1,.922-.922A.923.923,0,0,1,955,3212.6Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,955,3211.211Z"
          transform="translate(-929.663 -3181.646)"
          fill={color}
        />
        <path
          id="Path_3619"
          data-name="Path 3619"
          d="M968.788,3212.6a.922.922,0,1,1,.922-.922A.923.923,0,0,1,968.788,3212.6Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,968.788,3211.211Z"
          transform="translate(-940.28 -3181.646)"
          fill={color}
        />
        <path
          id="Path_3620"
          data-name="Path 3620"
          d="M914.486,3212.6a.924.924,0,0,1-.591-.214.23.23,0,0,1,.295-.353.463.463,0,1,0,.034-.737.23.23,0,0,1-.261-.378.922.922,0,1,1,.524,1.682Z"
          transform="translate(-898.645 -3181.646)"
          fill={color}
        />
        <path
          id="Path_3621"
          data-name="Path 3621"
          d="M927.436,3212.6a.922.922,0,1,1,.922-.922A.923.923,0,0,1,927.436,3212.6Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,927.436,3211.211Z"
          transform="translate(-908.427 -3181.646)"
          fill={color}
        />
        <path
          id="Path_3622"
          data-name="Path 3622"
          d="M948.112,3199.673a.922.922,0,1,1,.922-.922A.924.924,0,0,1,948.112,3199.673Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,948.112,3198.288Z"
          transform="translate(-924.354 -3171.691)"
          fill={color}
        />
        <path
          id="Path_3623"
          data-name="Path 3623"
          d="M961.9,3199.673a.922.922,0,1,1,.922-.922A.924.924,0,0,1,961.9,3199.673Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,961.9,3198.288Z"
          transform="translate(-934.972 -3171.691)"
          fill={color}
        />
        <path
          id="Path_3624"
          data-name="Path 3624"
          d="M975.68,3199.673a.922.922,0,1,1,.63-1.6.23.23,0,0,1-.314.335.463.463,0,1,0-.035.706.23.23,0,1,1,.279.365A.911.911,0,0,1,975.68,3199.673Z"
          transform="translate(-945.589 -3171.691)"
          fill={color}
        />
        <path
          id="Path_3625"
          data-name="Path 3625"
          d="M920.544,3199.673a.922.922,0,1,1,.922-.922A.924.924,0,0,1,920.544,3199.673Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,920.544,3198.288Z"
          transform="translate(-903.119 -3171.691)"
          fill={color}
        />
        <path
          id="Path_3626"
          data-name="Path 3626"
          d="M934.328,3199.673a.922.922,0,1,1,.922-.922A.924.924,0,0,1,934.328,3199.673Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,934.328,3198.288Z"
          transform="translate(-913.737 -3171.691)"
          fill={color}
        />
        <path
          id="Path_3627"
          data-name="Path 3627"
          d="M941.22,3186.75a.922.922,0,1,1,.922-.923A.924.924,0,0,1,941.22,3186.75Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,941.22,3185.365Z"
          transform="translate(-919.045 -3161.737)"
          fill={color}
        />
        <path
          id="Path_3628"
          data-name="Path 3628"
          d="M955,3186.75a.922.922,0,1,1,.922-.923A.923.923,0,0,1,955,3186.75Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,955,3185.365Z"
          transform="translate(-929.663 -3161.737)"
          fill={color}
        />
        <path
          id="Path_3629"
          data-name="Path 3629"
          d="M968.788,3186.75a.922.922,0,1,1,.922-.923A.924.924,0,0,1,968.788,3186.75Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,968.788,3185.365Z"
          transform="translate(-940.28 -3161.737)"
          fill={color}
        />
        <path
          id="Path_3630"
          data-name="Path 3630"
          d="M914.657,3186.75a.918.918,0,0,1-.492-.142.23.23,0,0,1,.245-.388.463.463,0,1,0-.016-.773.23.23,0,0,1-.261-.378.923.923,0,1,1,.523,1.682Z"
          transform="translate(-898.816 -3161.737)"
          fill={color}
        />
        <path
          id="Path_3631"
          data-name="Path 3631"
          d="M927.436,3186.75a.922.922,0,1,1,.922-.923A.924.924,0,0,1,927.436,3186.75Zm0-1.385a.463.463,0,1,0,.463.463A.463.463,0,0,0,927.436,3185.365Z"
          transform="translate(-908.427 -3161.737)"
          fill={color}
        />
        <path
          id="Path_3632"
          data-name="Path 3632"
          d="M948.112,3173.828a.922.922,0,1,1,.922-.922A.923.923,0,0,1,948.112,3173.828Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,948.112,3172.442Z"
          transform="translate(-924.354 -3151.784)"
          fill={color}
        />
        <path
          id="Path_3633"
          data-name="Path 3633"
          d="M961.9,3173.828a.922.922,0,1,1,.922-.922A.923.923,0,0,1,961.9,3173.828Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,961.9,3172.442Z"
          transform="translate(-934.972 -3151.784)"
          fill={color}
        />
        <path
          id="Path_3634"
          data-name="Path 3634"
          d="M975.68,3173.828a.922.922,0,1,1,.632-1.594.23.23,0,1,1-.315.334.463.463,0,1,0-.035.7.23.23,0,0,1,.281.364A.916.916,0,0,1,975.68,3173.828Z"
          transform="translate(-945.589 -3151.784)"
          fill={color}
        />
        <path
          id="Path_3635"
          data-name="Path 3635"
          d="M920.544,3173.828a.922.922,0,1,1,.922-.922A.923.923,0,0,1,920.544,3173.828Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,920.544,3172.442Z"
          transform="translate(-903.119 -3151.784)"
          fill={color}
        />
        <path
          id="Path_3636"
          data-name="Path 3636"
          d="M934.328,3173.828a.922.922,0,1,1,.922-.922A.923.923,0,0,1,934.328,3173.828Zm0-1.385a.463.463,0,1,0,.463.463A.464.464,0,0,0,934.328,3172.442Z"
          transform="translate(-913.737 -3151.784)"
          fill={color}
        />
        <path
          id="Path_3637"
          data-name="Path 3637"
          d="M948.112,3226.38a.923.923,0,1,1,.922-.923A.924.924,0,0,1,948.112,3226.38Zm0-1.386a.463.463,0,1,0,.463.463A.463.463,0,0,0,948.112,3224.995Z"
          transform="translate(-924.354 -3192.263)"
          fill={color}
        />
        <path
          id="Path_3638"
          data-name="Path 3638"
          d="M961.9,3226.38a.923.923,0,1,1,.922-.923A.924.924,0,0,1,961.9,3226.38Zm0-1.386a.463.463,0,1,0,.463.463A.464.464,0,0,0,961.9,3224.995Z"
          transform="translate(-934.972 -3192.263)"
          fill={color}
        />
        <path
          id="Path_3639"
          data-name="Path 3639"
          d="M975.68,3226.38a.923.923,0,1,1,.632-1.594.23.23,0,1,1-.315.334.463.463,0,1,0-.318.8.457.457,0,0,0,.276-.091.23.23,0,0,1,.275.368A.915.915,0,0,1,975.68,3226.38Z"
          transform="translate(-945.589 -3192.263)"
          fill={color}
        />
        <path
          id="Path_3640"
          data-name="Path 3640"
          d="M920.544,3226.38a.923.923,0,1,1,.922-.923A.924.924,0,0,1,920.544,3226.38Zm0-1.386a.463.463,0,1,0,.463.463A.464.464,0,0,0,920.544,3224.995Z"
          transform="translate(-903.119 -3192.263)"
          fill={color}
        />
        <path
          id="Path_3641"
          data-name="Path 3641"
          d="M934.328,3226.38a.923.923,0,1,1,.922-.923A.924.924,0,0,1,934.328,3226.38Zm0-1.386a.463.463,0,1,0,.463.463A.463.463,0,0,0,934.328,3224.995Z"
          transform="translate(-913.737 -3192.263)"
          fill={color}
        />
        <path
          id="Path_3642"
          data-name="Path 3642"
          d="M872.348,3142.387h-8.434a1.388,1.388,0,0,1-1.386-1.457l1.3-25.963a1.387,1.387,0,0,1,1.386-1.318H868.6a.46.46,0,0,1,.459.46,1.181,1.181,0,1,0,2.363,0,.46.46,0,0,1,.459-.46h7.846a.46.46,0,0,1,.459.46,1.181,1.181,0,1,0,2.363,0,.46.46,0,0,1,.459-.46H886.4a1.387,1.387,0,0,1,1.386,1.318l.349,6.613a.459.459,0,0,1-.918.048l-.349-6.615a.468.468,0,0,0-.468-.447h-2.979a2.1,2.1,0,0,1-4.1,0h-7.028a2.1,2.1,0,0,1-4.1,0h-2.979a.468.468,0,0,0-.468.445l-1.3,25.963a.468.468,0,0,0,.468.492h8.434a.46.46,0,0,1,0,.919Z"
          transform="translate(-859.141 -3106.851)"
          fill={color}
        />
      </g>
    </svg>
  );
}
