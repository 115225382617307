import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { MusumiStatusStyled } from './styled';
import { Buttons } from 'components';

const MusumiStatus = ({
  theme_standard,
  icon,
  title,
  description,
  btnLabel,
  onClick,
  btnTxtColor,
  btnBgColor,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <MusumiStatusStyled>
      <div className={customClass}>
        <div className="ms_logo_wrap">
          <img
            className="ms_logo"
            alt="misumi logo"
            src="/assets/images/logos/logo.png"
          />
        </div>
        {title && <div className="ps_title">{title}</div>}
        <div className="ps_label">{description}</div>
        <div className="ps_btn_wrap">
          <Buttons.BgStandard
            theme_cancel_btn
            bgColor={btnBgColor}
            txtColor={btnTxtColor}
            label={btnLabel}
            onClick={onClick}
          />
        </div>
      </div>
    </MusumiStatusStyled>
  );
};

MusumiStatus.propTypes = {};

export default MusumiStatus;
