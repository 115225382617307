import React from 'react';

export default function renderIcon({ width = 111, height = 111 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 112 112"
    >
      <g id="Group_755" data-name="Group 755" transform="translate(-158 -317)">
        <circle
          id="Ellipse_272"
          data-name="Ellipse 272"
          cx="56"
          cy="56"
          r="56"
          transform="translate(158 317)"
          fill="red"
        />
        <g
          id="Group_753"
          data-name="Group 753"
          transform="translate(-367 -4013.645)"
        >
          <g
            id="Group_753-2"
            data-name="Group 753"
            transform="translate(-6 0.645)"
            opacity="0.141"
          >
            <rect
              id="Rectangle_996"
              data-name="Rectangle 996"
              width="78"
              height="22"
              rx="10"
              transform="translate(569.201 4350.645) rotate(45)"
              fill="#020202"
            />
            <rect
              id="Rectangle_997"
              data-name="Rectangle 997"
              width="78"
              height="22"
              rx="10"
              transform="translate(553.645 4405.799) rotate(-45)"
              fill="#020202"
            />
          </g>
          <g
            id="Group_754"
            data-name="Group 754"
            transform="translate(-6 0.645)"
          >
            <rect
              id="Rectangle_996-2"
              data-name="Rectangle 996"
              width="78"
              height="22"
              rx="10"
              transform="translate(569.201 4350.645) rotate(45)"
              fill="#fff"
            />
            <rect
              id="Rectangle_997-2"
              data-name="Rectangle 997"
              width="78"
              height="22"
              rx="10"
              transform="translate(553.645 4405.799) rotate(-45)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
