import styled from "styled-components";

export const SplashContainerStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
  }
  // .alert {
  //   text-align: center;
  //   color: red;
  // }
`;
