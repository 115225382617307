import styled from 'styled-components';

export const BottomTabStyled = styled.div`
  .body_tap_body_layout {
    border-radius: 1rem;
    padding: 20px 14px 14px 14px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
  .bottom_tap_header_layout {
    margin-bottom: 21px;
    border-radius: 5px;
    display: flex;
    background: ${({ theme }) => theme.COLORS.GRAY_3};
    .header_item {
      border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
      flex: 1;
      height: 26px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      cursor: pointer;
      &.acitve {
        background: ${({ theme }) => theme.COLORS.BLUE_3};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        border-radius: 5px;
      }
      &:last-child {
        border-right: 0px;
      }
    }
  }
  .theme_standard {
  }
`;
