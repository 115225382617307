import styled from 'styled-components';

export const ActivityAcceptType3FormStyled = styled.div`
  margin-top: 80px;
  .input_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 26px;
    row-gap: 16px;
    .input_wrap {
    }
  }
  .input_checkbox_wrap {
    margin-top: 70px;
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
  }
`;
