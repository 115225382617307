import React from 'react';
import { RewardHistoryContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import { userService } from 'apiServices';
import { RenderCommaMoney } from 'utils/functions/formatMoney';
import { ExchangeRewardStep3 } from '../../RewardSystem/Steps';
import { Cards, Displays, Tables, Modals } from 'components';

class RewardHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_MY_REWARD_HISTORY();
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  handleClickHistory = (e) => {
    if (e.reward_type === '1') {
      let dataCheck = moment(e.barcode_expire).format('YYYY-MM-DD');
      let today = moment().format('YYYY-MM-DD');
      if (
        moment(today).isBefore(dataCheck) ||
        moment(dataCheck).isSame(today) === true
      ) {
        this.setState({
          isShowModal: true,
          renderModal: (
            <ExchangeRewardStep3
              data={{ name: e.reward_name, code: e.reward_e_counpon_ref_code }}
              onCancel={this.handleCloseModal}
            />
          ),
        });
      } else {
        this.setState({
          isShowModal: true,
          renderModal: (
            <Cards.MusumiStatus
              title={'ขออภัย!'}
              description={'QR Code ของคุณหมดอายุแล้ว'}
              btnLabel="ย้อนกลับ"
              onClick={() => {
                this.setState({
                  isShowModal: false,
                });
              }}
            />
          ),
        });
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const { data, isLoading, isShowModal, renderModal } = this.state;
    return (
      <RewardHistoryContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Tables.HeaderRowPanel
            columns={columns()}
            data={data}
            onClickRow={this.handleClickHistory}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardHistoryContainerStyled>
    );
  }
}

const columns = () => [
  {
    title: 'วันที่',
    field: 'created_at',
    headerStyle: { textAlign: 'center' },
    render: ({ origin, value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    title: 'ของรางวัล',
    field: 'reward_name',
  },
  {
    title: 'คะแนนที่ใช้แลก',
    field: 'reward_redeem_point',
    headerStyle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    style: {
      textAlign: 'center',
    },
    render: ({ origin, value }) => RenderCommaMoney(value),
  },
  {
    title: 'จำนวน',
    field: 'reward_redeem_amount',
    headerStyle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    style: {
      textAlign: 'center',
    },
    render: ({ origin, value }) => RenderCommaMoney(value),
  },
  {
    title: '',
    field: 'reward_type',
    headerStyle: { width: 300 },
    style: { width: 300 },
    render: ({ origin, value }) => {
      switch (value) {
        case '1':
          let dataCheck = moment(origin.barcode_expire).format('YYYY-MM-DD');
          let today = moment().format('YYYY-MM-DD');
          return (
            <Displays.Status
              label={'QR CODE'}
              color={
                moment(today).isBefore(dataCheck) ||
                moment(dataCheck).isSame(today) === true
                  ? ''
                  : 'gray'
              }
            />
          );
        default:
          return '';
      }
    },
  },
];

export default withRouter(RewardHistoryContainer);
