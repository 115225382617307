import styled from 'styled-components';

export const ActivityDetailContainerStyled = styled.div`
  padding: 20px 8px 98px 8px;
  .activity_item {
    margin-bottom: 10px;
  }
  .description_label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
`;
