import React, { useEffect } from 'react';
import { ShippingFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ShippingForm = ({ initialValues, onSubmit }) => {
  const schema = yup.object().shape({
    name_shipping: yup.string().required('Name is required'),
    phone_shipping: yup.string().required('Phone number is required'),
    address_shipping: yup.string().required('Address is required'),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        name_shipping: `${initialValues.firstname} ${initialValues.lastname}`,
        phone_shipping: initialValues.phone_number,
        address_shipping: initialValues.address,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ShippingFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_title">กรอกที่อยู่จัดส่ง</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="ชื่อ-นามสกุล"
                errors={errors.name_shipping?.message}
              />
            )}
            name="name_shipping"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="เบอร์โทรศัพท์มือถือ"
                errors={errors.phone_shipping?.message}
              />
            )}
            name="phone_shipping"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_34">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextArea
                {...field}
                label="ที่อยู่จัดส่งของรางวัล"
                errors={errors.address_shipping?.message}
              />
            )}
            name="address_shipping"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard theme_stadard_btn type="submit" label={'ตกลง'} />
      </form>
    </ShippingFormStyled>
  );
};

ShippingForm.propTypes = {};

export default ShippingForm;
