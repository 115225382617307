import styled from 'styled-components';

export const ExchangeRewardStep2Styled = styled.div`
  width: 100%;
  max-height: calc(100vh - 123px - 108px);
  overflow: auto;
  .body_exchange {
    border-radius: 20px;
    margin: auto;
    max-width: 354px;
    padding: 14px 14px 23px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    .logo {
      width: 50%;
      height: auto;
    }
    .small_text {
      margin-top: 20px;
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      &.no_mt {
        margin-top: 0px;
      }
      &.red {
        margin-top: 16px;
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
    .title {
      margin-top: 6px;
      color: ${({ theme }) => theme.COLORS.RED_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S50};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
    .btn_layout {
      margin-top: 25px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn_body {
        width: 47%;
      }
    }
  }
`;
