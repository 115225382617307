import React from "react";
import { connect } from "react-redux";
import { SplashContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import queryString from "query-string";
import { setReduxAuthen } from "store/reducers/authenReducer";

const liff = window.liff;

class SplashContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.initialLineLiff();
  }

  initialLineLiff = async () => {
    let { page } = queryString.parse(this.props.router.location.search);
    await liff.init({ liffId: `1657677235-6JQKe5Qb` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      const accessToken = liff.getAccessToken();
      const getProfile = await liff.getProfile();
      let params = {
        access_token: accessToken,
        user_id: getProfile.userId,
      };
      let res = await userService.POST_LINE_LOGIN(params);
      if (res && res.status === 200) {
        localStorage.setItem("token", res.token);
        let resProfile = await userService.GET_MY_PROFILE();
        if (resProfile && resProfile.status === 200) {
          this.props.setReduxAuthen(resProfile.data);
          this.props.router.navigate(
            !page ? ROUTE_PATH.ACTIVITY : ROUTE_PATH[page]
          );
        }
      } else {
        this.props.router.navigate(
          `${ROUTE_PATH.REGISTER}?lineID=${res.data.lineRefID}`
        );
      }
    } else {
      liff.login();
    }
  };

  render() {
    return (
      <SplashContainerStyled>
        <div>
          <img
            className="logo"
            alt="logo"
            src="/assets/images/logos/logo.png"
          />
          {/* <div className="alert">
            <div>ขณะนี้ระบบ MISUMI PRIVILEGE กำลังปิดปรับปรุง</div>
            <div>และคาดการณ์ว่าจะกลับมาใช้งาน</div>
            <div>ได้อีกครั้งในวันพุธที่ 23 สิงหาคม 2566</div>
            ขออภัยในความไม่สะดวกเป็นอย่างสูง
          </div> */}
        </div>
      </SplashContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SplashContainer));
