import styled from 'styled-components';
import { IMG_PATH } from 'utils/constants/imgPath';

export const NavbarWidgetStyled = styled.div`
  width: 100%;
  height: 123px;
  background-color: ${({ theme }) => theme.COLORS.BLUE_1};
  background-image: ${({ bg }) =>
    bg ? `url(${IMG_PATH + bg})` : 'url(/assets/images/bg/bg.png)'};
  background-position: center;
  background-size: cover;
  .header_row {
    height: 100%;
    display: flex;
    justify-content: space-betweeen;
    align-items: center;
    .header_left_col {
      flex: 1;
      height: calc(100% - 30px);
      overflow: hidden;
      padding: 15px;
      .nav_logo_wrap {
        margin: auto;
        width: 100%;
        height: auto;
        .nav_logo {
          width: 100%;
          height: auto;
        }
      }
    }
    .header_right_col {
      flex-shrink: 0;
      flex-basis: 140px;
      .nav_point {
        margin-bottom: -24px;
        border-radius: 107px;
        border: 4px solid ${({ theme }) => theme.COLORS.WHITE_1};
        position: relative;
        width: 107px;
        height: 107px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.ORANGE_2};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S35};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        .nav_p_label {
          position: absolute;
          top: -8px;
          right: -18px;
          width: 80px;
          height: 25px;
          background-image: url(/assets/images/icons/point.png);
          background-size: contain;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .nav_point_label {
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S10};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          }
        }
        .coin_img {
          position: absolute;
          bottom: 0px;
          right: -20px;
          width: 34px;
          height: auto;
        }
      }
    }
  }
`;
