import styled from 'styled-components';

export const TextFieldStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  input {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.GRAY_4 : theme.COLORS.GRAY_3};
    padding: 8px 9px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    cursor: ${({ disabled }) => (!disabled ? 'auto' : 'not-allowed')};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    }
    &.mb_25 {
      margin-bottom: 25px;
    }
  }
  input:focus {
    border-color: ${({ theme }) => theme.COLORS.BLUE_3};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
    input {
      border-radius: 21px;
      border: none;
      padding: 0px 30px;
      background: ${({ theme }) => theme.COLORS.GRAY_6};
    }
  }
`;
