export const ROUTE_PATH = {
  ROOT: '/',
  SPLASH: '/splash',
  LOGIN: '/login',
  REGISTER: '/register',
  SYSTEM: '/system',
  ACTIVITY: '/system/activity',
  ACTIVITY_DETAIL: '/system/activity/:id',
  REWARD: '/system/reward',
  REWARD_CARD: '/system/reward/card',
  REWARD_ELECTRONIC: '/system/reward/electronic',
  REWARD_ETC: '/system/reward/etc',
  HISTORY: '/system/history',
  TRANSACTION_HISTORY: '/system/history/transaction',
  REWARD_HISTORY: '/system/history/reward',
  PROFILE: '/system/profile',
};
