import React from 'react';

export default function renderIcon({
  color = '#fff',
  width = 35.488,
  height = 38.267,
}) {
  return (
    <svg
      id="Group_578"
      data-name="Group 578"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.488 38.267"
    >
      <path
        id="Path_3643"
        data-name="Path 3643"
        d="M1477.87,3123.493a.738.738,0,0,1-.077-1.473c3.669-.392,4.639-5.459,4.639-8.084a.738.738,0,0,1,1.477,0c0,3.084-1.246,9.051-5.96,9.552A.608.608,0,0,1,1477.87,3123.493Zm-3.735-1.13a.74.74,0,0,1-.492-.188c-2.234-2-3.026-5.686-3.026-8.239a.739.739,0,0,1,1.477,0c0,2.426.783,5.573,2.533,7.137a.738.738,0,0,1-.492,1.289Z"
        transform="translate(-1463.059 -3104.684)"
        fill={color}
      />
      <path
        id="Path_3644"
        data-name="Path 3644"
        d="M1439.854,3167.858a.738.738,0,0,1-.739-.738v-7.644c0-3.406,3.061-4.974,5.761-6.357,2.488-1.275,4.837-2.478,4.837-4.851v-1.563a.739.739,0,1,1,1.477,0v1.563c0,3.275-3,4.81-5.641,6.165-2.55,1.306-4.958,2.54-4.958,5.043v7.644A.737.737,0,0,1,1439.854,3167.858Z"
        transform="translate(-1439.116 -3129.592)"
        fill={color}
      />
      <path
        id="Path_3645"
        data-name="Path 3645"
        d="M1511.712,3153.657a.742.742,0,0,1-.363-.1c-1.968-1.113-3.979-2.613-3.979-5.292v-1.563a.738.738,0,1,1,1.477,0v1.563c0,1.809,1.291,2.91,3.23,4a.74.74,0,0,1-.365,1.383Z"
        transform="translate(-1490.994 -3129.592)"
        fill={color}
      />
      <path
        id="Path_3646"
        data-name="Path 3646"
        d="M1461.76,3212.579a.737.737,0,0,1-.738-.738v-6.3a.739.739,0,0,1,1.477,0v6.3A.737.737,0,0,1,1461.76,3212.579Z"
        transform="translate(-1455.766 -3174.313)"
        fill={color}
      />
      <path
        id="Path_3647"
        data-name="Path 3647"
        d="M1478.639,3173.618a.74.74,0,0,1-.338-.082l-5.472-2.824a.739.739,0,0,1,.678-1.314l5.472,2.824a.739.739,0,0,1-.34,1.4Z"
        transform="translate(-1464.436 -3147.338)"
        fill={color}
      />
      <path
        id="Path_3648"
        data-name="Path 3648"
        d="M1495.976,3173.62a.739.739,0,0,1-.338-1.4l5.517-2.824a.738.738,0,1,1,.673,1.314l-5.516,2.824A.734.734,0,0,1,1495.976,3173.62Z"
        transform="translate(-1481.772 -3147.34)"
        fill={color}
      />
      <path
        id="Path_3649"
        data-name="Path 3649"
        d="M1488.27,3194.56a.91.91,0,0,1-.527-.22l-2.432-2.475a.74.74,0,0,1-.188-.7l1.26-4.822a.739.739,0,0,1,1.429.374l-1.155,4.416,1.617,1.645,1.651-1.649-2.37-9.1a.738.738,0,1,1,1.429-.371l2.476,9.513a.739.739,0,0,1-.192.708l-2.476,2.475A.734.734,0,0,1,1488.27,3194.56Z"
        transform="translate(-1474.067 -3156.293)"
        fill={color}
      />
      <path
        id="Path_3650"
        data-name="Path 3650"
        d="M1481.556,3087.7a.759.759,0,0,1-.2-.027.739.739,0,0,1-.513-.91c.563-2.02.982-3.768.406-4.69a2.383,2.383,0,0,0-1.7-.9.738.738,0,0,1-.6-.753.489.489,0,0,0-.176-.369,4.711,4.711,0,0,0-3.126-.865c-2.39,0-4.038.573-4.9,1.7-.933,1.225-1.032,3.2-.3,5.875a.738.738,0,1,1-1.423.392c-.866-3.141-.683-5.551.545-7.162,1.155-1.517,3.2-2.287,6.074-2.287a5.983,5.983,0,0,1,4.188,1.316,2.094,2.094,0,0,1,.5.81,3.457,3.457,0,0,1,2.167,1.455c.9,1.435.446,3.421-.236,5.869A.739.739,0,0,1,1481.556,3087.7Z"
        transform="translate(-1461.444 -3077.712)"
        fill={color}
      />
      <path
        id="Path_3651"
        data-name="Path 3651"
        d="M1483.17,3101.465a.74.74,0,0,1-.717-.563c-.146-.6-.5-.877-1.122-1.322a4.156,4.156,0,0,1-1.716-2,12.513,12.513,0,0,1-2.817.429c-2.607.2-4.229.443-4.718,2.863a.738.738,0,1,1-1.447-.292c.734-3.639,3.684-3.864,6.053-4.044a7.713,7.713,0,0,0,3.077-.62.739.739,0,0,1,1.1.552,2.482,2.482,0,0,0,1.329,1.912,3.681,3.681,0,0,1,1.7,2.171.738.738,0,0,1-.718.914Z"
        transform="translate(-1463.059 -3091.474)"
        fill={color}
      />
      <path
        id="Path_3652"
        data-name="Path 3652"
        d="M1529.01,3185.885h-2.823a.736.736,0,0,1-.707-.525l-.278-.925a5.78,5.78,0,0,1-.624-.234l-.831.431a.742.742,0,0,1-.863-.133l-2-2a.738.738,0,0,1-.133-.862l.431-.83a6.641,6.641,0,0,1-.269-.626l-.9-.278a.738.738,0,0,1-.52-.7v-2.824a.738.738,0,0,1,.52-.7l.9-.278a6.664,6.664,0,0,1,.269-.626l-.431-.832a.738.738,0,0,1,.133-.862l2-2a.739.739,0,0,1,.862-.133l.822.427a5.769,5.769,0,0,1,.635-.266l.279-.9a.737.737,0,0,1,.7-.52h2.823a.737.737,0,0,1,.705.52l.28.9a6.676,6.676,0,0,1,.624.268l.832-.431a.738.738,0,0,1,.862.133l2,2a.739.739,0,0,1,.133.862l-.445.859c.084.194.165.391.239.594l.936.28a.739.739,0,0,1,.527.708v2.824a.74.74,0,0,1-.527.708l-.936.28c-.074.2-.155.4-.239.593l.445.859a.739.739,0,0,1-.133.862l-2,2a.741.741,0,0,1-.862.133l-.839-.435c-.208.093-.413.169-.615.236l-.279.928A.736.736,0,0,1,1529.01,3185.885Zm-2.274-1.476h1.724l.234-.776a.738.738,0,0,1,.492-.494,5.323,5.323,0,0,0,1.072-.418.738.738,0,0,1,.7-.01l.693.359,1.233-1.233-.359-.693a.736.736,0,0,1-.019-.639,9.453,9.453,0,0,0,.446-1.13.74.74,0,0,1,.5-.5l.777-.232v-1.725l-.777-.232a.743.743,0,0,1-.5-.5,9.209,9.209,0,0,0-.444-1.127.737.737,0,0,1,.019-.64l.359-.693-1.233-1.235-.693.359a.737.737,0,0,1-.7-.01,5.236,5.236,0,0,0-1.07-.458.744.744,0,0,1-.493-.489l-.229-.741h-1.735l-.229.741a.744.744,0,0,1-.493.489,4.385,4.385,0,0,0-1.037.439.736.736,0,0,1-.731.03l-.694-.359-1.233,1.235.359.693a.737.737,0,0,1-.011.7,5.234,5.234,0,0,0-.458,1.069.738.738,0,0,1-.49.494l-.74.23v1.734l.74.23a.736.736,0,0,1,.489.492,5.234,5.234,0,0,0,.46,1.07.74.74,0,0,1,.01.7l-.359.693,1.233,1.233.693-.359a.732.732,0,0,1,.732.03,4.5,4.5,0,0,0,1.019.392.776.776,0,0,1,.512.5Z"
        transform="translate(-1500.212 -3147.618)"
        fill={color}
      />
      <path
        id="Path_3653"
        data-name="Path 3653"
        d="M1541.339,3195.3a3.757,3.757,0,0,1,0-7.514,4.118,4.118,0,0,1,1.158.157.739.739,0,0,1-.406,1.422,2.647,2.647,0,0,0-.753-.1,2.281,2.281,0,1,0,2.3,2.26,2.589,2.589,0,0,0-.1-.709.739.739,0,0,1,1.42-.407,4.064,4.064,0,0,1,.158,1.116A3.738,3.738,0,0,1,1541.339,3195.3Z"
        transform="translate(-1513.974 -3161.38)"
        fill={color}
      />
    </svg>
  );
}
