import React from 'react';
import { PreviewHowToStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Icons } from 'components';

class PreviewHowTo extends React.Component {
  render() {
    const { srcPreview, onClose } = this.props;
    return (
      <PreviewHowToStyled>
        <div className="pvh_close_wrap" onClick={onClose}>
          <Icons.CloseX />
        </div>
        <img
          className="pvh_img"
          alt="how to find cu id"
          src={IMG_PATH + srcPreview}
        />
      </PreviewHowToStyled>
    );
  }
}

export default PreviewHowTo;
