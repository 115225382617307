import { ROUTE_PATH } from 'utils/constants/routePath';
import TopTab from 'components/Navigations/TopTab';
import HomeContainer from 'containers/HomeSystem/HomeContainer';
import ActivityDetailContainer from 'containers/HomeSystem/ActivityDetailContainer';
import RewardContainer from 'containers/RewardSystem/RewardContainer';
import RewardElectronicContainer from 'containers/RewardSystem/RewardElectronicContainer';
import RewardETCContainer from 'containers/RewardSystem/RewardETCContainer';
import TransactionHistoryContainer from 'containers/HistorySystem/TransactionHistoryContainer';
import RewardHistoryContainer from 'containers/HistorySystem/RewardHistoryContainer';
import ProfileContainer from 'containers/ProfileSystem/ProfileContainer';

const systemRoutes = [
  {
    key: 'home',
    name: 'Home',
    path: ROUTE_PATH.ACTIVITY,
    element: <HomeContainer />,
  },
  {
    key: 'activity',
    name: 'Activity',
    path: ROUTE_PATH.ACTIVITY_DETAIL,
    element: <ActivityDetailContainer />,
  },
  {
    key: 'reward',
    name: 'Reward',
    path: ROUTE_PATH.REWARD,
    element: (
      <TopTab
        showIcon
        data={[
          {
            key: 'reward_eletronic',
            name: (
              <>
                <div>บัตรกำนัล</div>
                <div>อิเล็คทรอนิกส์</div>
              </>
            ),
            path: ROUTE_PATH.REWARD_ELECTRONIC,
            element: <RewardElectronicContainer />,
          },
          {
            key: 'reward_card',
            name: 'บัตรกำนัล',
            path: ROUTE_PATH.REWARD_CARD,
            element: <RewardContainer />,
          },
          {
            key: 'reward_etc',
            name: 'ของรางวัลอื่นๆ',
            path: ROUTE_PATH.REWARD_ETC,
            element: <RewardETCContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'reward_eletronic',
        name: 'บัตรกำนัลอิเล็คทรอนิกส์',
        path: ROUTE_PATH.REWARD_CARD,
        element: <RewardContainer />,
      },
      {
        key: 'reward_card',
        name: 'บัตรกำนัล',
        path: ROUTE_PATH.REWARD_ELECTRONIC,
        element: <RewardElectronicContainer />,
      },
      {
        key: 'reward_etc',
        name: 'ของรางวัลอื่นๆ',
        path: ROUTE_PATH.REWARD_ETC,
        element: <RewardETCContainer />,
      },
    ],
  },
  {
    key: 'history',
    name: 'History',
    path: ROUTE_PATH.HISTORY,
    element: (
      <TopTab
        data={[
          {
            key: 'user_interface',
            name: 'ประวัติการสะสมคะแนน',
            path: ROUTE_PATH.TRANSACTION_HISTORY,
            element: <TransactionHistoryContainer />,
          },
          {
            key: 'campaign_data',
            name: 'ประวัติการแลกคะแนน',
            path: ROUTE_PATH.REWARD_HISTORY,
            element: <RewardHistoryContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'user_interface',
        name: 'ประวัติการสะสมคะแนน',
        path: ROUTE_PATH.TRANSACTION_HISTORY,
        element: <TransactionHistoryContainer />,
      },
      {
        key: 'campaign_data',
        name: 'ประวัติการแลกคะแนน',
        path: ROUTE_PATH.REWARD_HISTORY,
        element: <RewardHistoryContainer />,
      },
    ],
  },
  {
    key: 'profile',
    name: 'Profile',
    path: ROUTE_PATH.PROFILE,
    element: <ProfileContainer />,
  },
];

export default systemRoutes;
