import styled from 'styled-components';

export const ExchangeShippingStepStyled = styled.div`
  width: 100%;
  max-height: calc(100vh - 123px - 108px);
  overflow: auto;
  .ex_wrap {
    border-radius: 20px;
    margin: auto;
    max-width: 354px;
    padding: 9px 15px 38px 15px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
