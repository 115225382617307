import styled from 'styled-components';

export const InputAmoutStyled = styled.div`
  .input_amount_container {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_7};
    height: 28px;
    display: flex;
    .ia_control {
      flex-shrink: 0;
      flex-basis: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      cursor: pointer;
    }
    .ia_value {
      flex: 1;
      border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_7};
      border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_7};
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
  }

  .theme_standard {
  }
`;
