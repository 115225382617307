import React from 'react';
import { RegisterFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const RegisterForm = ({ onSubmit, handleClickShowModal }) => {
  const schema = yup.object().shape({
    firstname: yup.string().required('Firstname is required'),
    lastname: yup.string().required('Lastname is required'),
    phone_number: yup
      .string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Invalid phone number'),
    email: yup.string().email().required('Email is required'),
    user_id: yup.string().required('User ID is required'),
    cu_id: yup
      .string()
      .required('Cu ID is required')
      .min(6, 'Cu ID to short')
      .max(6, 'Cu ID is too long'),
    // is_agreement: yup.bool().oneOf([true], 'Accept must be checked'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RegisterFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title_form">ลงทะเบียนเข้าใช้งาน</div>
        <div className="title_form mb_12">ระบบมิซูมิพรีวิเลจ</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'ชื่อ'}
                errors={errors.firstname?.message}
              />
            )}
            name="firstname"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'นามสกุล'}
                errors={errors.lastname?.message}
              />
            )}
            name="lastname"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'เบอร์โทรศัพท์มือถือ'}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'Email'}
                errors={errors.email?.message}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'User ID'}
                errors={errors.user_id?.message}
              />
            )}
            name="user_id"
            defaultValue=""
          />
          <div
            className="des_text"
            onClick={() => handleClickShowModal('user_id')}
          >
            User ID ที่เลือกล๊อกอินเข้าใช้งานเว็ปไซด์และแอปพลิเคชั่นมิซูมิ
          </div>
        </div>
        <div className="input_wrap mb_180">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'รหัสลูกค้า 6 หลัก'}
                errors={errors.cu_id?.message}
              />
            )}
            name="cu_id"
            defaultValue=""
          />
          <div
            className="des_text"
            onClick={() => handleClickShowModal('cu_id')}
          >
            วิธีการดูรหัสลูกค้า
          </div>
        </div>
        {/* <div className="input_checkbox_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label="ยอมรับเงื่อนไขนโยบายการรักษาข้อมูลส่วนบุคคล"
                errors={errors.is_agreement?.message}
              />
            )}
            name="is_agreement"
            defaultValue={false}
          />
        </div> */}
        <Buttons.BgStandard type="submit" label={'ลงทะเบียน'} />
      </form>
    </RegisterFormStyled>
  );
};

RegisterForm.propTypes = {};

export default RegisterForm;
