import styled from 'styled-components';

export const InputSearchStyled = styled.div`
  padding: 14px 14px 16px 14px;
  .label {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_search_row {
    display: flex;
    column-gap: 7px;
    input {
      border-radius: 8px;
      border: none;
      width: 100%;
      height: 42px;
      box-sizing: border-box;
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      padding: 8px 9px;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      &::placeholder {
        // color: ${({ theme }) => theme.COLORS.BLUE_2};
        // font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
      &.mb_25 {
        margin-bottom: 25px;
      }
    }
    input:focus {
      border-color: ${({ theme }) => theme.COLORS.BLUE_3};
    }
    .ins_btn_col {
      flex-shrink: 0;
      flex-basis: 106px;
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
