import React from 'react';
import { PolicyStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Buttons } from 'components';

class Policy extends React.Component {
  render() {
    const { onCancel, onAccept } = this.props;
    return (
      <PolicyStyled>
        <div className="policy_title">นโยบายการรักษาข้อมูลส่วนบุคคล</div>
        <div className="policy_label">
          การเก็บข้อมูลส่วนบุคคล
          นี้อยู่ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
          โดยบริษัทมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคล ซึ่งตามกฎหมายเรียกว่า
          ‘ผู้ควบคุมข้อมูลส่วนบุคคล’
          โดยมีพนักงานที่บริษัทมอบหมายโดยเฉพาะให้มีหน้าที่ดำเนินการเกี่ยวกับการเก็บ
          รวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของบริษัท
          ซึ่งตามกฎหมายเรียกว่า ‘ผู้ประมวลผลข้อมูลส่วนบุคคล’ ส่วนท่านถือเป็น
          ‘เจ้าของข้อมูลส่วนบุคคล’ ตามกฎหมายนี้
        </div>
        <div className="policy_label">
          ข้อ 1. ข้อมูลส่วนบุคคลอะไรบ้างที่บริษัทเก็บรวบรวม ใช้ และ/หรือเปิดเผย
          เราจะเก็บรวบรวมข้อมูลส่วนบุคคลซึ่งเป็นข้อมูลที่ทำให้สามารถระบุตัวตนขอ
          งท่านได้ ไม่ว่าทางตรงหรือทางอ้อม ได้แก่
          ข้อมูลที่ท่านให้ไว้โดยตรงจากการลง ทะเบียนผ่านระบบ MISUMI PRIVILEGE
          การลงทะเบียนเข้าร่วมกิจกรรม ต่างๆ ของบริษัท คุกกี้ ข้อมูลการทำรายการ
          และประสบการณ์การใช้งานผ่านหน้า เว็บไซต์ ผู้ที่ได้รับมอบหมาย
          หรือช่องทางอื่นใด เช่น ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล อายุ
          วันเดือนปีเกิด สถานภาพสมรส เลข ประจำตัวประชาชน เลขหนังสือเดินทาง
          ข้อมูลการติดต่อ เช่น ที่อยู่อาศัย สถานที่ทำงาน หมายเลขโทรศัพท์ อีเมล
          ไอดีไลน์ ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP Address MAC Address
          Cookie ID ข้อมูลอื่นๆ เช่น การใช้งานเว็บไซต์ เสียง ภาพนิ่ง
          ภาพเคลื่อนไหว และข้อมูลอื่นใด
          ที่ถือว่าเป็นข้อมูลส่วนบุคคลภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล
        </div>
        <div className="policy_label">
          ข้อ 2. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูล
          เราจะนำข้อมูลของท่านมาใช้เพื่อการพัฒนาและปรับปรุงเว็บไซต์
          https://th.misumi-ec.com/ แพลตฟอร์มออนไลน์ และช่องทางโซเชียลมีเดียอื่นๆ
          ในเครือ ตลอดจนการวิเคราะห์และประมวลผลข้อมูลส่วนบุคคล เพื่อให้ตอบโจทย์
          การใช้งานของผู้ใช้งาน
          ด้วยวิธีการทางอิเล็กทรอนิกส์แก่ท่านให้มีประสิทธิภาพ มากยิ่งขึ้น
          หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ บริษัทจะแจ้งให้ท่านทราบ
          เพื่อขอความยินยอม และจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
          ทั้งนี้ บริษัทจะไม่กระทำการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์อย่างอื่น
          ที่นอกเหนือจากวัตถุประสงค์ที่ได้แจ้งไว้กับท่านไว้ก่อนหรือ
          ขณะเก็บรวบรวม
        </div>
        <div className="policy_label mb_18">
          ข้อ 3. การคุ้มครองข้อมูลส่วนบุคคล
          บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นอย่างดีตามมาตรการเชิงเทคนิค
          (Technical Measure) และมาตรการเชิงบริหารจัดการ (Organizational
          Measure)
        </div>
        <div className="p_action_row">
          <div className="p_btn_col">
            <Buttons.BgStandard
              theme_cancel_btn
              label={'ไม่ยอมรับ'}
              onClick={onCancel}
            />
          </div>
          <div className="p_btn_col">
            <Buttons.BgStandard
              theme_stadard_btn
              label={'ยอมรับ'}
              onClick={onAccept}
            />
          </div>
        </div>
      </PolicyStyled>
    );
  }
}

export default withRouter(Policy);
