import styled from 'styled-components';

export const UploadStyled = styled.div`
  width: fit-content;
  max-width: 160px;
  .dropzone {
    width: 100%;
    max-width: 160px;
    cursor: pointer;
    .input_upload_container {
      position: relative;
      .upload_img {
        width: 100%;
        height: auto;
      }
      .icon_uploaded {
        position: absolute;
        right: 13px;
        bottom: 13px;
      }
    }
  }
  .img_preview {
    margin-top: 12px;
    width: 100%;
    height: auto;
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
