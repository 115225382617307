import styled from 'styled-components';

export const RewardStyled = styled.div`
  .reward_container {
    position: relative;
    width: 100%;
    padding-top: 75.15%;
    background-color: ${({ theme }) => theme.COLORS.BLUE_1};
    background-image: ${({ img }) => `url(${img})`};
    background-position: center;
    background-size: cover;
    cursor: pointer;
    .r_point {
      position: absolute;
      left: 10px;
      top: 10px;
      border-radius: 30px;
      border: 4px solid ${({ theme }) => theme.COLORS.WHITE_1};
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.COLORS.ORANGE_2};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    }
    .point_wrap {
      position: absolute;
      top: 37px;
      left: 43px;
      width: 20px;
      .poin_img {
        width: 100%;
        height: auto;
      }
    }
  }

  .theme_standard {
  }
`;
