import { ExchangeRewardStep3Styled } from './styled';
import { Buttons, Icons } from 'components';
import BottomTab from 'components/Navigations/BottomTab';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

const ExchangeRewardStep3 = ({ data, onCancel, onAccept }) => {
  return (
    <ExchangeRewardStep3Styled>
      <div className="body_exchange">
        <div className="close" onClick={onCancel}>
          <Icons.CloseX />
        </div>
        <img className="logo" alt="logo" src="/assets/images/logos/logo.png" />
        <div className="tab_zone">
          <BottomTab
            data={[
              {
                key: 'reward_eletronic',
                name: 'รหัส',
                element: (
                  <div>
                    {(data.code.search('https') !== -1 ||
                      data.code.search('http') !== -1) && (
                      <div className="btn_link">
                        <a
                          href={data.code}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Go to redeem
                        </a>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                key: 'reward_card',
                name: 'บาร์โค้ด',
                element: (
                  <div style={{ textAlign: 'center' }}>
                    <Barcode
                      value={data.code}
                      width={0.5}
                      displayValue={false}
                    />
                  </div>
                ),
              },
              {
                key: 'reward_etc',
                name: 'คิวอาร์โค้ด',
                element: (
                  <div style={{ textAlign: 'center' }}>
                    <QRCode value={data.code} size={200} />
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="code_label">{data.code}</div>
        <div className="product_label">{data.name}</div>
        <div className="btn_layout">
          <div className="btn_body">
            <Buttons.BgStandard
              theme_cancel_btn
              label={'กลับหน้าหลัก'}
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    </ExchangeRewardStep3Styled>
  );
};

export default ExchangeRewardStep3;
