import styled from 'styled-components';

export const PreviewHowToStyled = styled.div`
  border-radius: 10px;
  padding: 20px 24px;
  max-width: 380px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .pvh_close_wrap {
    margin-bottom: 0.75rem;
    text-align: right;
    cursor: pointer;
  }
  .pvh_img {
    width: 100%;
    height: auto;
  }
`;
