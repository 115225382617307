import styled from 'styled-components';

export const PageLayoutStyled = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  min-height: 100vh;
  background-color: ${({ theme }) => theme.COLORS.BLUE_1};
  background-image: url(/assets/images/bg/bg-white.jpg);
  background-position: center;
  background-size: cover;
`;
