import styled from 'styled-components';

export const ExchangeRewardStep1Styled = styled.div`
  width: 100%;
  max-height: calc(100vh - 123px - 108px);
  overflow: auto;
  .body_exchange {
    border-radius: 20px;
    margin: auto;
    max-width: 354px;
    padding: 14px 14px 23px 14px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    .img_show {
      position: relative;
      width: 100%;
      height: 184px;
      border-radius: 21px;
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .detail_block {
        position: absolute;
        right: 10px;
        top: 10px;
        .db_amout_label {
          margin-bottom: 2px;
          border-radius: 14px;
          padding: 3px;
          min-width: 82px;
          background: ${({ theme }) => theme.COLORS.WHITE_1}E6;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S10};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        }
        .db_expire_label {
          border-radius: 14px;
          padding: 3px 4px;
          min-width: 82px;
          background: ${({ theme }) => theme.COLORS.WHITE_1}E6;
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S10};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        }
      }
    }
    .label_show {
      margin-top: 10px;
      .box_label {
        padding: 5px;
        border-radius: 18px;
        background: ${({ theme }) => theme.COLORS.BLUE_4};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        text-align: center;
        min-width: 75%;
        display: flex;
        justify-content: center;
      }
    }
    .title {
      margin-top: 10px;
      margin-bottom: 8px;
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
    .sub_text {
      margin-bottom: 14px;
      word-break: break-word;
      white-space: pre-wrap;
      line-height: 17px;
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
    .small_text {
      margin-bottom: 2px;
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      &.mb {
        margin-bottom: 12px;
      }
    }
    .btn_layout {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      column-gap: 16px;
      .btn_body {
        flex: 1;
      }
    }
  }
`;
