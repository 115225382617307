import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const configSystemSlice = createSlice({
  name: 'configSystemReducer',
  initialState,
  reducers: {
    setReduxConfigSystem: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setReduxConfigSystem } = configSystemSlice.actions;

export default configSystemSlice.reducer;
