import React from 'react';
import { connect } from 'react-redux';
import { RewardContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import moment from 'moment';
import {
  ExchangeRewardInputStep1,
  ExchangeRewardStep2,
  ExchangeShippingStep,
} from '../Steps';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { toast } from 'react-toastify';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { Cards, Displays, Icons, Modals } from 'components';

class RewardContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_REWARD_LIST(
      `?pagination=false&is_active=true&type=2&sort=sequence`
    );
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleRenderModal = (key, e) => {
    const { authenRedux } = this.props;
    const { dataSelected, amountSelected } = this.state;
    switch (key) {
      case 'step1':
        this.setState({
          isShowModal: true,
          dataSelected: e,
          renderModal: (
            <ExchangeRewardInputStep1
              data={e}
              onCancel={this.handleCloseModal}
              onAccept={(f) => {
                this.handleRenderModal('step2', f);
              }}
            />
          ),
        });
        break;
      case 'step2':
        this.setState({
          isShowModal: true,
          amountSelected: e,
          renderModal: (
            <ExchangeRewardStep2
              data={dataSelected}
              amount={e}
              onCancel={() => this.handleRenderModal('step1', dataSelected)}
              onAccept={async () => {
                let params = {
                  reward_id: dataSelected._id,
                  redeem_amount: e,
                  is_request_verify: true,
                };
                let res = await userService.POST_REDEEM_REWARD(params);
                if (res && res.status === 200) {
                  this.handleRenderModal('step3');
                } else {
                  this.setState({
                    renderModal: (
                      <Cards.MusumiStatus
                        title={'ขออภัย!'}
                        description={
                          'คะแนนของคุณไม่เพียงพอต่อการแลกของรางวัลชิ้นนี้'
                        }
                        btnLabel="ย้อนกลับ"
                        onClick={() => {
                          this.setState({
                            isShowModal: false,
                          });
                        }}
                      />
                    ),
                  });
                }
              }}
            />
          ),
        });
        break;
      case 'step3':
        this.setState({
          isShowModal: true,
          renderModal: (
            <ExchangeShippingStep
              data={dataSelected}
              customerProfile={authenRedux}
              onCancel={this.handleCloseModal}
              onSubmit={async (values) => {
                let params = {
                  ...values,
                  reward_id: dataSelected._id,
                  redeem_amount: amountSelected,
                };
                let res = await userService.POST_REDEEM_REWARD(params);
                if (res && res.status === 200) {
                  let resWallet = await userService.GET_MY_WALLET();
                  if (resWallet && resWallet.status === 200) {
                    this.props.setReduxWallet(resWallet.data);
                    this.handleRenderModal('step4');
                  }
                } else {
                  toast.error('ไม่สามารถแลกได้ กรุณาเช็คเงื่อนไงในการแลก');
                }
              }}
            />
          ),
        });
        break;
      case 'step4':
        this.setState({
          isShowModal: true,
          renderModal: (
            <Cards.ProcessStatus
              theme_standard
              icon={<Icons.Success />}
              title={'สำเร็จ'}
              description={
                <>
                  <div>เพิ่มที่อยู่ในการจัดส่งของรางวัลเรียบร้อย</div>
                  <div>ของรางวัลจะถูกส่งออกภายใน 10-15 วันทำการ</div>
                </>
              }
              btnLabel="ตกลง"
              onClick={() => {
                this.props.router.navigate(ROUTE_PATH.ACTIVITY);
              }}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { isShowModal, renderModal, isLoading, data } = this.state;
    return (
      <RewardContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="reward_layout">
            {data?.docs &&
              data.docs.map((e, i) => (
                <div
                  key={i}
                  className="reward_wrap"
                  onClick={() => this.handleRenderModal('step1', e)}
                >
                  <Cards.Reward
                    img={IMG_PATH + e.img_path}
                    point={e.redeem_point}
                    amount={e.total_amount - e.redeem_amount}
                    expire={moment(e.expired_at).format('DD/MM/YY')}
                  />
                </div>
              ))}
          </div>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RewardContainer));
