import React from 'react';
import { HomeContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Cards, Displays, Icons, Inputs, Modals } from 'components';

class HomeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([
      this.fetchCampaginList(),
      this.fetchCampaginHistory(),
    ]);
    if (res) {
      console.log('res',res)
      this.setState({
        isLoading: false,
        data: [
          ...this.renderData(res[0].docs, res[1]),
          ...res[1]
            .filter((e) => e.campaign_publish_type === '2' && e.campaign_detail && e.campaign_detail.is_active)
            .map((f) => ({ ...f.campaign_detail, isRegisted: true })),
        ],
      });
    }
  };

  fetchCampaginList = async () => {
    let res = await userService.GET_CAMPAIGN_LIST(
      `?pagination=false&is_active=true&publish_type=1`
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  fetchCampaginHistory = async () => {
    let res = await userService.GET_MY_CAMPAIGN_HISTORY(`?pagination=false`);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  renderData = (data, dataRegis) => {
    let reduce = data.reduce((result, dataReduce) => {
      let find = dataRegis.find((e) => e.campaign_ref_id === dataReduce._id);
      if (find) {
        result.push({ ...dataReduce, isRegisted: true });
      } else {
        result.push({ ...dataReduce, isRegisted: false });
      }
      return result;
    }, []);
    return reduce;
  };

  handleClick = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITY}/${e._id}`);
  };

  handleSearch = async (e) => {
    this.setState({
      isBgLoading: true,
    });
    let res = await userService.GET_CAMPAIGN_LIST(
      `?pagination=false&is_active=true&publish_type=2&code=${e}`
    );
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: true,
      });
      if (res.data.docs.length === 0) {
        this.setState({
          isBgLoading: false,
          isShowModal: true,
          renderModal: (
            <Cards.ProcessStatus
              icon={<Icons.Fail />}
              description={'ไม่พบรหัสกิจกรรมนี้'}
              btnLabel="ตกลง"
              onClick={() => {
                this.setState({
                  isShowModal: false,
                });
              }}
            />
          ),
        });
      } else {
        this.props.router.navigate(
          `${ROUTE_PATH.ACTIVITY}/${res.data.docs[0]._id}`
        );
      }
    } else {
      this.setState({
        isBgLoading: false,
        isShowModal: true,
        renderModal: (
          <Cards.ProcessStatus
            icon={<Icons.Success />}
            description={'ไม่พบรหัสกิจกรรมนี้'}
            btnLabel="ตกลง"
            onClick={() => {
              this.setState({
                isShowModal: false,
              });
            }}
          />
        ),
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    const { isLoading, isBgLoading, isShowModal, data, renderModal } =
      this.state;
    return (
      <HomeContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="home_body_sec">
            {data &&
              data.map((e, i) => (
                <div
                  key={i}
                  className="activity_item"
                  onClick={() => this.handleClick(e)}
                >
                  <Cards.Activity
                    img={IMG_PATH + e.front_img}
                    isRegisted={e.isRegisted}
                  />
                </div>
              ))}
          </div>
        )}
        <div className="search_wrap">
          <Inputs.InputSearch
            label="กรอกรหัสกิจกรรมพิเศษที่คุณได้รับ"
            onSearch={this.handleSearch}
          />
        </div>
        <Modals.BodyEmpty
          isForceAction
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </HomeContainerStyled>
    );
  }
}

export default withRouter(HomeContainer);
