import React, { useEffect } from 'react';
import { ActivityAcceptType3FormStyled } from './styled';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ActivityAcceptType3Form = ({ data, onSubmit }) => {
  const schema = yup.object().shape({
    is_agreement: yup
      .bool()
      .nullable()
      .oneOf([true], 'Please accept condition'),
  });

  const {
    control,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      campaign_document: [
        ...Array.from(Array(data.img_upload_number).keys()).map((e) => ({
          id: e,
          img_path: '',
        })),
      ],
    },
  });

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'campaign_document', // unique name for your Field Array
  });

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = (values) => {
    let error = false;
    values.campaign_document.forEach((e, i) => {
      if (!e.img_path) {
        setError(`campaign_document[${i}].img_path`, {
          type: 'manual',
          message: 'Please select the required options',
        });
        error = true;
      }
    });
    if (!error) {
      onSubmit && onSubmit(values);
    }
  };

  return (
    <ActivityAcceptType3FormStyled>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <div className="input_row">
          {fields.map((field, index) => (
            <div key={field.id} className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Upload
                    {...field}
                    errors={
                      errors.campaign_document &&
                      errors?.campaign_document[index]?.img_path?.message
                    }
                  />
                )}
                name={`campaign_document.${index}.img_path`}
                defaultValue=""
              />
            </div>
          ))}
        </div>
        <div className="input_checkbox_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label="ยอมรับเงื่อนไขกิจกรรม"
                disabled={data?.isRegisted}
                errors={errors.is_agreement?.message}
              />
            )}
            name="is_agreement"
            defaultValue={data?.isRegisted || false}
          />
        </div>
        {data?.isRegisted ? (
          <Buttons.BgStandard
            type="button"
            disabled
            txtColor={data.btn_joined_text_color}
            bgColor={data.btn_joined_button_color}
            label={data.btn_joined_label || 'คุณเข้าร่วมกิจกรรมแล้ว'}
          />
        ) : (
          <Buttons.BgStandard
            type="submit"
            bgColor={data?.btn_join_button_color}
            txtColor={data?.btn_join_text_color}
            label={data?.btn_join_label || 'ยืนยันเข้าร่วมกิจกรรม'}
          />
        )}
      </form>
    </ActivityAcceptType3FormStyled>
  );
};

ActivityAcceptType3Form.propTypes = {};

export default ActivityAcceptType3Form;
