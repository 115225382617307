import styled from 'styled-components';

export const PopupWidgetStyled = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 99;
  .popup_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.COLORS.BLACK_1}66;
  }
  .popup_img_figture {
    position: relative;
    .popup_img {
      width: 200px;
      height: auto;
      z-index: 2;
    }
    .popup_close {
      position: absolute;
      top: -10px;
      right: -12px;
      cursor: pointer;
    }
  }
`;
