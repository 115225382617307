import styled from 'styled-components';

export const ActivityAcceptType2FormStyled = styled.div`
  margin-top: 60px;
  .input_wrap {
    margin-bottom: 16px;
  }
  .input_checkbox_wrap {
    margin-top: 100px;
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
  }
`;
