import React from 'react';
// import PropTypes from 'prop-types'
import { LoadingStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components'

const Loading = ({
  theme_standard_loading,
}) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  });

  return (
    <LoadingStyled>
      <div className={customClass}>
        <Icons.Loading />
      </div>
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;
