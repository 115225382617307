import React from 'react';
import { connect } from 'react-redux';
import { RegisterContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { Policy, PreviewHowTo } from './Modals';
import { Cards, Displays, Forms, Headers, Icons, Modals } from 'components';

class RegisterContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_CONFIG_SYSTEM();
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs[0],
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
      isShowModal: false,
    });
  };

  handleSubmit = (values) => {
    let { lineID } = queryString.parse(this.props.router.location.search);
    let params = {
      ...values,
      line_id: lineID,
    };
    this.setState({
      isShowModal: true,
      dataProfile: params,
      renderModal: (
        <Policy onCancel={this.handleCloseModal} onAccept={this.AcceptPDPA} />
      ),
    });
  };

  AcceptPDPA = async () => {
    const { dataProfile } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let res = await userService.POST_REGISTER_CUSTOMER(dataProfile);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      this.props.setReduxAuthen(res.data);
      this.setState({
        isBgLoading: false,
        renderModal: (
          <Cards.ProcessStatus
            icon={<Icons.Success />}
            description={'ลงทะเบียนสำเร็จ'}
            btnLabel="ตกลง"
            onClick={() => {
              this.props.router.navigate(ROUTE_PATH.ACTIVITY);
            }}
          />
        ),
      });
    } else {
      this.setState({
        isBgLoading: false,
        isShowModal: false,
      });
      toast.error(res.Message);
    }
  };

  renderShowModal = (e) => {
    const { data } = this.state;
    switch (e) {
      case 'user_id':
        this.setState({
          renderModal: (
            <PreviewHowTo
              srcPreview={data.see_user_manual_img}
              onClose={this.handleCloseModal}
            />
          ),
          isShowModal: true,
        });
        break;
      case 'cu_id':
        this.setState({
          renderModal: (
            <PreviewHowTo
              srcPreview={data.see_cu_manual_img}
              onClose={this.handleCloseModal}
            />
          ),
          isShowModal: true,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { isShowModal, isBgLoading, renderModal } = this.state;
    return (
      <RegisterContainerStyled>
        <Headers.Logo />
        <Displays.BGLoading visible={isBgLoading} />
        <Forms.RegisterForm
          onSubmit={this.handleSubmit}
          handleClickShowModal={this.renderShowModal}
        />
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterContainer));
