import styled from 'styled-components';

export const ProfileFormStyled = styled.div`
  .form_title {
    margin-bottom: 4px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLUE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S38};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_wrap {
    margin-bottom: 4px;
    &.mb_40 {
      margin-bottom: 40px;
    }
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 154px;
  }
`;
