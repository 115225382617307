import styled from 'styled-components';

export const RegisterFormStyled = styled.div`
  padding: 0 30px 30px 30px;
  .title_form {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLUE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S35};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    &.mb_12 {
      margin-bottom: 12px;
    }
  }
  .input_wrap {
    margin-bottom: 25px;
    &.mb_180 {
      margin-bottom: 120px;
    }
    .des_text {
      margin-top: 3px;
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      cursor: pointer;
    }
  }
  .input_checkbox_wrap {
    margin-bottom: 19px;
    display: flex;
    justify-content: center;
  }
`;
