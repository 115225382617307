import { NavbarWidgetStyled } from './styled';
import { useSelector } from 'react-redux';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RenderCommaMoney } from 'utils/functions/formatMoney';

const NavbarWidget = () => {
  const { configSystemRedux, walletRedux } = useSelector((state) => state);
  return (
    <NavbarWidgetStyled bg={configSystemRedux?.header_bg_img}>
      <div className="header_row">
        <div className="header_left_col">
          <div className="nav_logo_wrap">
            <img
              className="nav_logo"
              alt="misumi logo"
              src={
                configSystemRedux?.header_img
                  ? IMG_PATH + configSystemRedux.header_img
                  : '/assets/images/logos/logo-pri.png'
              }
            />
          </div>
        </div>
        <div className="header_right_col">
          <div className="nav_point">
            <div className="nav_p_label">
              <div className="nav_point_label">คะแนนของคุณ</div>
            </div>
            {RenderCommaMoney(walletRedux?.point || 0)}
            <img
              className="coin_img"
              alt="coin"
              src="/assets/images/icons/coin.png"
            />
          </div>
        </div>
      </div>
    </NavbarWidgetStyled>
  );
};

export default NavbarWidget;
